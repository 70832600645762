const JsonFile = {
  public: ['idxInfo', 'idxContact', 'head', 'question', 'about02', 'disclaimer02', 'rule02', 'idxApp', 'm_floatBtn', 'about_zh-TW', 'about_en-US', 'about_hi-IN', 'question_zh-TW', 'question_en-US', 'question_hi-IN', 'novice_zh-TW', 'novice_en-US', 'novice_hi-IN'],
  bw8: ['customer'],
  //fl88: ['customer', 'seo'],
  jdf: ['customer', 'seo', 'head_x', 'idxContact_x', 'seo_x', 'idxInfo_x'],
  qy8: ['customerAll', 'seo', 'hotGame'],
  howe: ['customer', 'seo'],
  //bu88: ['customer', 'seo', 'head_x', 'idxContact_x', 'seo_x', 'idxInfo_x'],
  lk11: ['customerAll'],
  db11: ['customerAll'],
  star: ['customerAll', 'seo', 'hotGame'],
  fl88: ['customerAll', 'seo', 'hotGame', 'idxService'],
  bu88: ['customerAll', 'seo', 'hotGame', 'idxService'],
  egyy: ['customer'],
  tyc: ['customerAll', 'seo', 'hotGame'],
  '7s68' : ['customerAll', 'seo', 'hotGame'],
  kb88: ['customer'],
  jh8: ['customerAll', 'seo', 'hotGame', 'idxService'],
  bos: ['customerAll', 'seo', 'hotGame', 'idxService'],
};

export function getJsonFile(prefix, name) {
  var idxInfo = [];
  try {
    if (Object.keys(JsonFile).indexOf(prefix) >= 0 && JsonFile[prefix].indexOf(name) >= 0)
      idxInfo = require(`@/website/${prefix}/json/${name}.json`);
    else if (JsonFile['public'].indexOf(name) >= 0)
      idxInfo = require(`@/website/${prefix}/json/${name}.json`);

  } catch (e) {
    console.log(e)
  }
  return idxInfo;
}
