export default {
  "help_gambling_tips": "Gambling Tips",

  "auto_transfer": "自動轉帳",
  "bind": "綁定",
  "bind_line": "Line綁定",
  "unbind": "解除綁定",
  "no": "否",
  "has_registered_account": "是否已有註冊過帳號?",
  "yes": "是",

  "withdraw_password": "安全密碼",
  "plz_withdraw_password": "請輸入安全密碼",
  "tip_withdraw_password": "請輸入4位數字的安全密碼",
  "forgot_safe_password": "忘記安全密碼",
  "forgot_safe_password_mark": "忘記安全密碼?",
  "send_safe_password": "發送安全密碼",
  "send_safe_password_reset": "秒後重新發送",

  "bind_0": "未綁定",
  "bind_1": "已綁定",
  "status_0": "處理中",
  "status_1": "處理完成",
  "status_2": "拒絕",
  "stroe_IBONS": "7-11超商",
  "stroe_FAMILYS": "全家超商",
  "stroe_HILIFE": "萊爾富超商",
  "stroe_OK": "OK超商",
  "stroe_OKMART": "OK超商",
  "pay_type_BankTransfer": "銀行轉帳",
  "pay_type_LinePay": "一卡通Money",
  "pay_type_JkoPay": "街口支付",
  "pay_type_IcashPay": "icash支付",
  'pay_type_PxPay': "全支付",
  "pay_type_Virtual": "虛擬幣支付",
  "pay_type_ThirdParty": "三方支付",
  "pay_type_ThirdParty_1": "銀行轉帳",
  "pay_type_ThirdParty_2": "一卡通Money",
  "pay_type_ThirdParty_4": "街口支付",
  "pay_type_ThirdParty_8": "超商繳費",
  "pay_type_ThirdParty_16": "條碼繳費",
  "pay_type_ThirdParty_32": "轉帳",
  "pay_type_ThirdParty_64": "網路ATM",
  "pay_type_ThirdParty_128": "信用卡",
  "pay_type_ThirdParty_256": "Paytm",
  "pay_type_ThirdParty_512": "UPI",
  "pay_type_ThirdParty_1024": "GCash",
  "pay_type_ThirdParty_2048": "PayMaya",
  "pay_type_ThirdParty_4096": "虛擬幣支付",
  "pay_type_ThirdParty_8192": "快捷支付",
  "pay_type_ThirdParty_16384": "快捷掃碼",
  "pay_type_ThirdParty_32768": "電子支付",
  "pay_type_8192": "快捷支付",
  "pay_type_16384": "快捷掃碼",
  "pay_type_32768": "電子支付",

  "all": "全部",
  "all_games": "全部遊戲",
  "all_activity": "全部優惠",
  "audio": "音效",
  "bind_bankcard": "請先前往綁定銀行卡",
  "bind_jkopay": "請先前往綁定JkoPay",
  "bind_linepay": "請先前往綁定一卡通Money",
  "bind_icashpay": "請先前往綁定icashPay",
  "bind_pxpay": "請先前往綁定全支付",
  "bind_virtualwallet": "請先前往綁定虛擬貨幣地址",
  "bind_usercard": "請先前往綁定身份證",
  "confirm": "確認",
  "confirm_cancel_order": "確認取消訂單?",
  "dont_discount": "不使用優惠",
  "examination_passed": "審核通過",
  "landscape": "請將您的螢幕<b>旋轉為直向</b>，擁有最佳使用體驗，謝謝！",
  "not_approved": "未通過審核",
  "under_review": "審核中",
  "review_returns": "待補件",
  "virtual_currency_channel": "虛擬幣通道",

  "details": "詳情",
  "illustrate": "說明",
  "tab_third_upi": "UPI",
  "tab_third_gcash": "GCash",
  "tab_third_quick_payment": "快捷支付",
  "tab_third_quick_scan": "快捷掃碼",
  "tab_third_epay": "電子支付",
  "deposit_payupi": "UPI ID",
  "deposit_plz_enter_upi": "請輸入UPI ID",
  "header_top_qa": "如何存取款",
  "header_top_line": "在線客服",
  "header_top_info": "常見問題",

  "tip": "小提示",
  "announcement": "公告",
  "system_title": "系統公告",
  "announcement_type": "公告分類",
  "item": "項目",
  "all_item": "所有項目",
  "all_item_a": "系統公告",
  "all_item_b": "優惠活動",
  "now": "目前在第",
  "page": "頁",
  "nomsg": "目前暫無公告",
  "type": "類型",
  "address": "地址",
  "status": "狀態",
  "soon1": "敬",
  "soon2": "請",
  "soon3": "期",
  "soon4": "待",
  "game_strategy":"博奕攻略",
  "member_appendLink":"代理合營",

  "order_id": "交易序號",
  "created_at": "交易時間",
  "status_name": "交易狀態",
  "fields_complete_at": "完成時間",
  "fields_demand": "流水需求",
  "fields_valid": "有效流水",
  "retreat": "退水",
  "total": "總計",
  "odd_number": "單數",
  "single_number": "單號",
  "beting": "投注點數",
  "amount_bet": "下注點數",
  "vaild_bet": "有效投注",
  "content_bet": "下注內容",
  "time_bet": "下注時間",
  "winlose": "輸贏",
  "platform_from": "轉出平台",
  "platform_to": "轉入平台",
  "points": "點數",
  "friend_num": "好友人數",
  "friend_list": "好友名單",
  "plz_select_friend": "請選擇好友",
  "amount_share": "回饋金",
  "first": "第一層",
  "second": "第二層",
  "valid_first": "一層有效投注",
  "valid_second": "二層有效投注",
  "amount_share_first": "一層回饋金",
  "amount_share_second": "二層回饋金",
  "fee": "手續費",
  "friend_shardmode": "好友共享模式，推薦你的好朋友，不論輸贏，都會將有效碼量的 【0.1%】 回饋給你。",
  "friend_shard_unlimit": "分享好友數量是無上限的，分享越多賺越多喔。",
  "friend_copy": "立刻複製你的推廣網址交給你的好友吧！",
  "friend_promotion_url": "你的專屬推廣網址，複製以下文字發送給好友",
  "shareReport": "查看分享紀錄",
  "hisbetReport": "遊戲紀錄",
  "user_hisSearch": "紀錄查詢",
  "user_histransfer": "贈點紀錄",

  "money_transfer": "銀行轉帳",
  "credit_card": "信用卡",
  "paytm": "Paytm",
  "ATM": "ATM",
  "webatm": "網路ATM",
  "third_atm": "ATM轉帳",
  "third_qrcode": "條碼繳費",
  "linepay": "一卡通Money",
  "linepaymoney": "一卡通Money",
  "jkopay": "街口支付",
  "icashpay": "icashPay",
  "pxpay": "全支付",
  "payItem_6_subtitle": "平均上分1分鐘",
  "ibon_pay": "7-11 ibon 列印繳費",
  "famiport_pay": "全家 FamiPort 列印繳費",
  "eighty_seven_percent": "有87%的人",
  "choose_this_option": "都選擇這個方案",
  "start": "開始使用",
  "downloadPic": "下載二維碼",
  "fields_account": "帳務項目",
  "fields_about": "相關資訊",
  "table_order_date": "訂單日期",
  "table_expire_date": "繳費期限",

  "no_data": "目前沒有資料哦！",
  "no_record": "目前沒有紀錄！",
  "login_invalid": "登入失效，請重新登入!",
  "error_sorry": "抱歉，您訪問的頁面出錯了",
  "error_back_home": "回首頁",
  "back": "返回",
  "search": "搜尋",
  "operating": "操作",
  "uphold_ing": "維護中",
  "hotpro_tip": "往下滑顯示更多遊戲",
  "hotpro_title": "熱門產品",
  "hotpro_menu": "功能表",
  "hotpro_menu2": "選單",
  "hotpro_hello": "，你好",
  "hotpro_switch": "遊戲項目靠左",
  "profile": "個人資料",
  "hisBet": "歷史投注",
  "home": "首頁",
  "just8899": "即時比分",
  "jackpot539": "即時開獎",
  "activity": "優惠活動",
  "share": "分享賺錢",
  "card_manage": "卡片管理",
  "bind_limit": "已達綁定上限",
  "card_bind_limit": "卡片已達綁定上限",
  "card_bind_view": "查看已綁定卡片",
  "bind_view": "查看已綁定資料",
  "receiptview": "查看收據",
  "web_msg": "站內信",
  "web_mail": "站內信",

  "sport": "體育賽事",
  "live": "真人娛樂",
  "egame": "電子遊戲",
  "lotto": "樂透彩球",
  "chess": "棋牌遊戲",
  "fish": "捕魚遊戲",
  "animal": "動物競技",

  "game_sport": "體育賽事",
  "game_live": "真人娛樂",
  "game_egame": "電子遊戲",
  "game_lotto": "樂透彩球",
  "game_chess": "棋牌遊戲",
  "game_fish": "捕魚遊戲",
  "game_animal": "動物競技",

  "s_hot": "熱門",
  "s_sport": "體育",
  "s_live": "真人",
  "s_egame": "電子",
  "s_lotto": "彩球",
  "s_chess": "棋牌",
  "s_animal": "動物",
  "s_fish": "捕魚",
  "s_slot": "電子",
  "s_slot2": "實機",
  "s_together": "百人",
  "s_jackpot": "封神榜",

  "blog": "部落格",
  "jackpot": "中獎",
  "jackpot_list": "中獎名單",
  "jackpot_in": "在",
  "jackpot_geting": "中，獲得",
  "jackpot_get": "獲得",
  "jackpot_dollar": "點",
  "recommend": "推薦",
  "s_desopit": "存取款",
  "s_turning": "轉點",
  "s_coin": "贈點",
  "s_wallet": "錢包",
  "s_share": "分享",

  "user_approve": "身份認證",
  "bind_data": "資料綁定",
  "learn_more": "了解更多",
  "share_money": "分享回饋金",
  "help_video": "影片介紹",
  "help_question": "常見問題",
  "help_download": "APP下載",
  "help_download_ios": "IOS 下載",
  "help_download_and": "android 下載",
  "help_download_only": "下載",
  "help_novice": "新手幫助",
  "help_game": "遊戲幫助",
  "help_check": "清除快取",
  "help_comp": "企業事務",
  "help_newspaper": "娛樂城新聞",
  "help_champion": "539即時開獎",
  "game_type": "遊戲分類",
  "game_platform": "遊戲平台",
  "precautions": "注意事項",

  "footer_declare": "本網站定義之「遊戲點數」，僅供娛樂使用，不得經由本網站進行現金轉售點數、兌換紅利商品。",
  "footer01_title": "簡易流程",
  "footer01_sub": "3分鐘存取款",
  "footer02_title": "產品服務",
  "footer02_sub": "最豐富最多樣娛樂",
  "footer03_title": "人性介面",
  "footer03_sub": "追求體驗用戶至上",
  "footer04_title": "專業品牌",
  "footer04_sub": "公司信譽值得信賴",
  "footer05_title": "用戶好評",
  "footer05_sub": "口碑好評推薦",
  "partner": "合作夥伴",
  "using_help": "使用幫助",
  "info_help": "資訊與幫助",
  "allday_service": "24 小時服務資訊",
  "phone_bet": "手機投注",
  "ios": "IOS",
  "android": "Android",

  "wallets": "主錢包",
  "DG": "DG真人",
  "AB": "AB真人",
  "SA": "SA真人",
  "WM": "WM真人",
  "SUPER": "SUPER體育",
  "101": "101彩票",
  "RG": "RG棋牌",
  "BWIN": "BWIN電子",
  "KA": "KA電子",
  "fish_GR": "好路捕魚",
  "fish_BWIN": "BWIN捕魚",
  "fish_OB": "DB捕魚",
  "fish_ZG": "ZG捕魚",
  "fish_RSG": "RSG捕魚",
  "hot_allbet": "歐博真人",
  "hot_DG": "DG真人",
  "hot_OB": "DB真人",
  "hot_WG": "WG彩票",
  "hot_ZG": "ZG電子",
  "hot_OB_sport": "DB體育",
  "hot_OB_game": "DB電子",
  "hot_WG": "WG彩票",
  "hot_RSG": "RSG電子",
  "hot_BOBHH": "高登棋牌",

  "uphold_time": "維護時間",
  "maintain_list": [
    {
      "title": "歐博",
      "date": "星期一",
      "time": "08:00~12:00"
    },
    {
      "title": "沙龍",
      "date": "星期一",
      "time": "11:00~13:30"
    },
    {
      "title": "WM",
      "date": "星期一",
      "time": "11:00~13:30"
    },
    {
      "title": "SUPER",
      "date": "星期一",
      "time": "12:30~16:00"
    },
    {
      "title": "DG",
      "date": "星期三",
      "time": "06:30~10:30"
    }
  ],

  "normal": "正常",
  "stop_using": "停用",
  "today": "今日",
  "yesterday": "昨日",
  "lastweek": "上周",
  "thisweek": "本周",
  "lastmonth": "上月",
  "thismonth": "本月",
  "startdate": "起始日期",
  "deadline": "結束日期",
  "platform": "遊戲類別 / 遊戲平台",
  "plz_select_feedback": "請選擇欄位",
  "account_number": "身份證字號",
  "plz_account_number": "請輸入身份證字號",
  "dateValid":"可查詢區間為最近的 {days} 天",

  "no_operating_authority": "無此功能操作權限!",
  "failed_request_plz_try_again": "請求失敗，請稍後重新嘗試",
  "upload_limit_over": "超過可上傳檔案容量限制",
  "click_bank_name_copy": "※溫馨提醒：點選<b><u>轉帳銀行</u></b>與<b><u>戶名</u></b>，即可複製",
  "fill_field": "請填寫此欄位",
  "fill_field_correctly": "請正確填寫此欄位",
  "plz_enter_username": "請輸入會員帳號",
  "plz_enter_coin_num": "請輸入金幣數量",
  "plz_enter_donate_points": "請輸入贈點帳號",
  "plz_enter_points_num": "請輸入贈點點數",
  "plz_enter_user_mobile": "請輸入會員帳號綁定的手機號碼",
  "plz_enter_CAPTCHA": "請輸入驗證碼",
  "plz_linpay_account": "請輸入一卡通Money帳號",
  "plz_jkopay_account": "請輸入街口支付帳號",
  "plz_icashpay_account": "請輸入icash支付帳號",
  "plz_pxpay_account": "請輸入綁定電話號碼",
  "gift_coins": "贈送金幣數量",

  "tip_captchas_required": "驗證碼格式錯誤",
  "tip_donate_cant_required": "贈點帳號格式錯誤",
  "tip_cant_required": "不能為空或者格式錯誤",
  "tip_cant_captcha_required": "驗證碼不能為空，請重新輸入",
  "get_agree": "我已年滿18歲並同意投注相關規範以及",
  "i_have_read": "我已詳閱",
  "terms": "服務條款",
  "tip_mobile_captcha_first": "請先發送手機驗證碼",
  "tip_plz_agree": "請先同意相關規範以及服務條款",
  "tip_id_ok": "帳號已經檢查成功",
  "tip_mobile_captcha_send": "手機驗證碼已發送",
  "tip_mobile_second_reset": "秒後重新發送",

  "dialogTipDigit_main_info": "請維持文件完整性，不能切角或切邊 / 反光 / 模糊不清 /遮住個人資料",
  "how_light": "如何避免反光？",
  "how_light_c1": "選擇光源充足的拍照場所",
  "how_light_c2": "不要使用閃光燈",
  "how_light_c3": "將拍攝物直立，可避免因光源所造成的反白",
  "how_blur": "如何避免模糊不清？",
  "how_blur_c1": "拍照之前要先對焦-手機：點一下螢幕、相機：輕按快門",
  "how_blur_c2": "拍照新增正本；不接受拍照影本",
  "tip_ok_sample": "正確範例",
  "tip_ok_c1": "身分證",
  "tip_ok_c2": "銀行存摺",
  "tip_exp": "參考範例",
  "tip_error_sample": "錯誤範例",
  "tip_error_c1": "切角或切邊",
  "tip_error_c2": "證件切角",
  "tip_error_c3": "無法辨識",
  "tip_error_c4": "部分反光",
  "tip_error_c5": "右下角反光",
  "tip_error_c6": "模糊不清",
  "dialogTipDigit_ok_c1": "數位帳戶資訊(一張)",
  "dialogTipDigit_ok_c2": "銀行卡+身分證(放一起拍一張)",
  "dialogTipDigit_ok_note":"",
  "dialogTipEntity_ok_c1": "身分證",
  "dialogTipEntity_ok_c2": "銀行存摺",

  "bw8_li_01": "請在點數未上分前【保留您的繳費收據】，以保障您的權益。",
  "bw8_li_02": "如您是使用【信用卡】方式儲值，將【收取10%手續費】，舉例:您儲值1,000元，實際獲得的遊戲點數為900點。",
  "bw8_li_03": "單號成立後請務必於【2小時內】繳費入款 ，超過時限單號即失效，提交單號後若超過2小時，請再重新提交入款單。",
  "bw8_li_04": "每組儲值繳費單號為【單次繳費使用】請勿重複繳款。",
  "bw8_li_05": "儲值時請繳申請時之【正確】的金額，假設您輸入儲值5,000，繳款時請繳款5,000元整，避免導致未收到款項。",
  "bw8_li_06": "如您【先前已有申請儲值】，但是尚未完成交易之情形，無法再次申請儲值，如需儲值，請洽客服協助再次開單。",
  "bw8_li_07": "如您有申請託售，並在【託售未處理完畢前】，請勿再申請儲值以免造成您權益上的損失。",
  "bw8_li_08": "託售時須達到儲值金額【一倍洗碼量】方可進行託售，如未達洗碼標準將無法成功託售。",
  "bw8_li_09": "若利用本娛樂城進行任何洗錢詐騙行為，本娛樂城有權利永久終止會員服務，不另行通知。",
  "bw8_li_10": "【請確實填寫本人手機號碼】避免儲值管道要求手機驗證，系統偵測錯誤號碼導致通道關閉。",

  "casino_introduce": "娛樂城遊戲介紹",
  "hot": "熱門推薦",
  "play_together": "百人共玩",
  "fighting": "競技對戰",
  "slot": "街機電子",
  "slot2": "實機電子",
  "fishing": "捕魚遊戲",
  "together": "百人共玩",

  "slot_description": " - 老虎機遊戲,老虎機玩法,老虎機台,線上拉霸機,角子機,slot,game",
  "chess_description": " - 龍虎,妞妞,牛牛,炸金花,線上百家樂, 番攤,色碟,棋牌遊戲博弈,棋牌遊戲玩法",
  "live_description": " - 歐博,沙龍,WM,DG,真人百家樂,線上百家樂,百家樂遊戲,百家樂賺錢,百家樂贏錢",
  "lottery_description": " - 539,天天樂,大樂透,六合彩,539明牌,地下539玩法,234星,全車,坐車,台號,不出牌,多選不中,自選不中,北京賽車,幸運飛艇,急速飛艇,賓果賓果,時時彩",
  "egame_description": " - 老虎機遊戲,老虎機玩法,老虎機台,線上拉霸機,角子機,slot,game",
  "sport_description": " - NBA,CBA,KBL,MLB歐冠盃,世足盃,運彩串關,運彩下注,運彩怎麼買,運彩場中,玩運彩,運動彩卷,台灣運彩,運彩威剛,運彩投注站",
  "animal_description": " - 鬥雞,動物競技,斗雞",

  "about_title01": "關於我們",
  "agent_title01": "代理合作",

  "check_title": "清緩存教學",
  "check_list": [
    {
      "imgPath": "ios-clear-history",
      "title": "在 iPhone、iPad 或 iPod touch 上清除 Safari 的瀏覽記錄和 Cookie",
      "cont1": "瞭解如何在「設定」中刪除瀏覽記錄、Cookie 和快取。",
      "cont2": "在裝置上清除資訊的方式如下：",
      "cont3": "若要清除瀏覽記錄和 Cookie，請前往「設定」>「Safari」，然後點一下「清除瀏覽記錄和網站資料」。清除 Safari 的瀏覽記錄、Cookie 和瀏覽資料，並不會更改「自動填寫」的資訊。",
      "cont4": "若要清除 Cookie 但保留瀏覽記錄，請前往「設定」>「Safari」>「進階」>「網站資料」，然後點一下「移除所有的網站資料」。",
      "cont5": "沒有瀏覽記錄或網站資料可清除時，設定會變成灰色。若已在「螢幕使用時間」中的「內容與隱私權限制」之下設定網頁內容限制，設定也會變成灰色。"
    },
    {
      "imgPath": "android-clear-history",
      "title": "在 Android裝置 上清除 Chrome 的瀏覽記錄和 Cookie",
      "cont1": "移除 Cookie 後，系統會將您登出所有的網站，而您儲存的偏好設定也可能會一併刪除。",
      "cont2": "在裝置上清除資訊的方式如下：",
      "cont3": "在 Android 手機開啟 Chrome 應用程式 ",
      "cont4": "依序輕觸網址列右邊的「更多」圖示 更多 > [設定]。",
      "cont5": "依序輕觸 [隱私權] > [清除瀏覽資料]。",
      "cont6": "選擇時間範圍，例如 [過去 1 小時] 或 [不限時間]。",
      "cont7": "勾選 [Cookie、媒體授權和網站資料]，並取消勾選其他項目。",
      "cont8": "依序輕觸 [清除資料] > [清除]。",
    }
  ],

  "disclaimer_title": "隱私權&安全性",
  "disclaimer_list": [
    {
      "title": "隱私權",
      "cont1": "我們致力為客戶保護隱私並提供一個最安全的遊戲平臺，我們在此網站蒐集的資料將會為您提供最卓越的服務，我們不會出賣或租賃您的個人資料給第三方，客戶所提供的個人資料均經過加密技術處理，並儲存在安全的、非公開的作業系統，對於有機會接觸客戶的個人資料的協助夥伴也必需遵守我們訂立的隱私保密規則。",
      "cont2": "您需要保管好您的帳號和密碼安全。不得允許任何其他人或協力廠商，包括任何未成年人使用或重複使用您的帳戶進行任何網站訪問及投注操作。本公司將有權拒絕支付由此產生的任何獎勵或獎金。您將獨自為協力廠商在帳戶中產生的所有損失負責。",
      "cont3": "您可能會收到一些定期的信件或簡訊，向您更新有關本公司產品的資訊與服務，當本網推出新穎，好玩和有趣的推廣活動，這些信件或簡訊就可幫助您充分享受到本網的服務。",
      "cont4": "我們的系統會自動記錄瀏覽者的網路IP位址，但我們絕不會記錄網站訪問者的電郵位址，而此項主要是用作網站流量統計之用。",
      "cont5": "本公司保留權力可自行對隱私政策做出任何修改，此政策如有任何更改均具有約束力，並立即生效。",
      "cont6": "本娛樂城絕對不會洩露您的個人資料給任何第三方，除非收到法庭傳單或可行性法律的要求及判決。",
      "cont7": "我們有權通過網站向有關付款平台服務提供商以及金融保險機構提供必要的個人信息以完成付款要求。",
      "cont8": "所有用戶提供的個人信息，其傳送都將通過安全端口（SSL 128 bit encryption Standard）並存放在公眾無法進入的保密環境之下。所有數據的內部出入都受到限制及嚴密的監控。",
      "cont9": "如有其他任何問題，請您聯繫24小時在線客服。"
    },
    {
      "title": "安全性",
      "cont1": "本公司網站是世界上最先進的網上博彩機構之一，致力於提供一個合法與規範的網上博彩環境。此外，我們採取一切安全防範措施，安裝了良好的科技設備，保證只為符合條件的玩家提供服務，那些禁止進行網上博彩的地區的使用者是不被允許下注的。",
      "cont2": "我們還採用了目前最好的加密技術和防火牆系統保護你的安全、隱私，並保證你享受公平的遊戲。由於採用了企業級別的加密科技(1024位RSA金鑰交換和 448位blowfish)，我們可以為你的資料提供比一般的商業級別的SSL更高的保護。這一科技目前被世界上主要的金融機構所採用。",
      "cont3": "所有銀行交易由國際金融機構在高標準的安全和機密的網路中進行。進入玩家帳戶資料也必須有玩家唯一的登錄ID和密碼。我們決不允許在我們的網站上有任何的欺詐行為。任何此類行為將導致帳戶立即被關閉，所有彩金將被沒收，也許還將對該玩家進行民事或刑事訴訟。",
      "danger": "本公司將保護玩家的權益永遠放在第一。"
    }
  ],

  "add_app_desktop": "新增APP iCON到桌面",
  "add_android_title": "以Chrome開啟本站，即可將本站加入手機畫面，讓您快速連結本站行動官網。",
  "add_android_step1": "點選瀏覽器右上方圖示",
  "add_android_step2": "選擇「加到主畫面」，即完成。",
  "dont_show": "不再顯示此問題",
  "add_app_ios_title": "以Safari開啟本站，即可將本站加入手機畫面，讓您快速連結本站行動官網。",
  "add_ios_step1": "請先點選瀏覽器下方",
  "add_ios_step2": "點擊「新增」至 桌面 ",
  "add_ios_step3": "點擊「新增」，完成！",

  "btn": {
    "showhidden": "顯示 / 隱藏",
    "send_refresh": "刷新",
    "send_new_pw": "發送新密碼",
    "get_CAPTCHA": "接收驗證碼",
    "verify_btn": "發送驗證",
    "error_feedback": "錯誤重新輸入",
    "cancel_edit": "重新輸入",
    "submit_confirm": "確定送出",
    "submit_check": "確認提交",
    "ok": "送出",
    "submit": "提交確認",
    "confirm": "確認",
    "cancel": "取消",
    "reset": "重設",
    "reset_again": "重新設定",
    "login": "登入",
    "login_en": "LOGIN",
    "signup": "註冊",
    "signup2": "註冊",
    "header_login_signup": "註冊與登入",
    "sign": "會員註冊",
    "agent_login": "代理登入",
    "agent_sign": "代理註冊",
    "sign_en": "JOIN NOW",
    "logout": "登出",
    "user_login": "會員登入",
    "user_verify": "會員驗證",
    "sign_up": "立即註冊",
    "join_now": "免費註冊",
    "delete": "刪除",
    "copy": "複製",
    "close": "關閉",
    "close_all": "全部關閉",
    "search": "搜尋",
    "select_all": "全選",
    "enter_game": "進入遊戲",
    "play_game_now": "立即遊戲",
    "free_try_play": "免費試玩",
    "try_play": "試玩",
    "CAPTCHA": "驗證碼",
    "turnpoint": "進行贈點"
  },

  "withdrawal": {
    "withdrawal": "託售",
    "treasure_trial": "託售試算",
    "treasure_note": "託售注意事項",
    "wallet_treasure": "點數託售",
    "treasure_records": "託售紀錄",
    "no_sale_record": "無託售紀錄！",
    "treasure_bank_name": "託售銀行",
    "treasure_amount": "託售金額",
    "treasure_bank_card": "託售的銀行卡",
    "choose_bank_feedback": "請選擇進行託售的銀行卡",
    "treasure_point": "託售點數",
    "treasure_point_limit": "託售點數需要介於",
    "plz_treasure_point": "請輸入託售點數",
    "treasure_precautions": "《託售注意事項》",
    "plz_treasure_agree": "請先同意託售注意事項",
    "note_1": "會員託售當日提領次數上限三次，每週皆有三次免收手續費，第四次託售必須收取託售點數的1% 加上50點作業費，(每周一00:00起~周日23:59止計算)",
    "note_2": "若利用本娛樂城進行任何洗錢詐騙行為，本娛樂城有權利永久終止會員服務，不另行通知。",
    "note_3": "若經風控部門查獲任何個人或是團體利用不誠實手法獲取不當利益時，同一個帳戶/家庭/電話號碼/儲值地點/銀行帳戶/相似或同一IP位置/網路環境等，公司保留取消或回收會員不當利益之權利。",
    "note_4": "會計部託售作業時間約為15～30分鐘",
  },

  "desopit": {
    "desopit": "儲值",
    "desopit_tycc": "儲值",
    "fields_order_id": "儲值序號",
    "fields_type": "儲值類型",
    "desopit_amount": "儲值金額",
    "hello_desopit_info": "您好，您的儲值資訊如下",
    "desopit_qrcode": "儲值二維碼",
    "desopit_mode": "儲值方式",
    "desopit_account": "儲值戶名",
    "desopit_code": "儲值代碼",
    "desopit_now": "馬上儲值",
    "desopit_tyc": "馬上儲值",
    "choose_paybank_select": "--- 請選擇進行儲值的銀行卡 ---",
    "choose_paybank_feedback": "請選擇進行儲值的銀行卡",
    "choose_paystore": "選擇儲值超商",
    "desopit_bank_card": "儲值的銀行卡",
    "point_desopit": "點數儲值",
    "desopit_point": "儲值點數",
    "plz_enter_point": "請輸入儲值點數",
    "desopit_limit": "儲值點數需要介於",
    "desopit_point_info": "點數儲值資料",
    "plz_deposit_receipt": "請上傳【儲值收據】",
    "upload_deposit_receipt": "上傳儲值收據",
    "deposit_receipt": "儲值收據",
    "deposit_records": "儲值紀錄",
    "no_deposit_records": "無儲值紀錄！",
    "deposit_detail": "儲值詳情",
    "deposit_others": "其他儲值方式",
    "crypto_address": "儲值的虛擬貨幣地址",
    "wallet_address": "儲值的錢包地址",
    "plz_choose_crypto_address_feedback": "請選擇進行儲值的虛擬貨幣地址",
    "plz_choose_crypto_address_select": "--- 請選擇進行儲值的虛擬貨幣地址 ---",
    "pay_virtual_currency": "虛擬貨幣儲值",
    "plz_enter_crypto": "請輸入儲值虛擬貨幣",
    "cancel": "取消儲值",
    "desopit_amount_range": "儲值的點數區間"
  },

  "discount": {
    "activity": "優惠",
    "choose_discount_select": "--- 請選擇優惠 ---",
    "choose_discount_feedback": "選擇您的優惠",
    "records": "優惠紀錄",
    "apply": "優惠申請",
    "btnYes": "我要參加",
    "time": "活動時間",
    "object": "活動對象",
    "content": "活動內容",
    "rule": "活動規則",
    "act_name": "活動名稱",
    "amount": "活動點數",
    "mode": "優惠方式",
    "nothing": "目前沒有資料哦！",
    "search": "搜尋",
    "type": "優惠方式",
    "name": "優惠名稱",
    "description": "優惠描述",
    "type_1": "本地儲值優惠",
    "type_2": "三方支付優惠",
    "type_4": "自助申請優惠",
    "application": "申請優惠",
    "applied": "已申請優惠",
    "first": "首儲限定",
    "full": "已申請人數已滿",
    "doing_d": "活",
    "doing_o": "動",
    "doing_i": "已",
    "doing_n": "結",
    "doing_g": "束"
  },

  "wallet": {
    "name": "錢包名稱",
    "plz_choose_out": "請選擇轉出錢包",
    "plz_choose_in": "請選擇轉入錢包",
    "back_wallet": "一鍵回主錢包",
    "digital_wallet": "電子錢包",
    "wallet_balance": "錢包餘額",
    "wallet_address": "錢包地址",
    "plz_input_wallet_address": "請輸入錢包地址",
    "deposit_wallet_address": "儲值的錢包地址",
    "tranfer_wallet_address": "轉帳的錢包地址",
    "enter_ob_tip": "進入{game}遊戲，點數為5:1顯示，例如主錢包點數1000，進入{game}將顯示200，轉回遊戲大廳也依照此比例兌換，例如{game}餘額200，轉回主錢包為1000點",
    "enter_exch_tip_10": "進入{game}遊戲，點數為10:1顯示，例如主錢包點數1000，進入{game}將顯示100，轉回遊戲大廳也依照此比例兌換，例如{game}餘額100，轉回主錢包為1000點",
    "mobile_wallet_info": "行動支付資料（請輸入要綁定的資訊，一旦綁定所有提款將自動匯至此行動錢包）",
    "choose_transfer_virtual_address_select": "--- 請選擇轉帳虛擬貨幣地址 ---",
  },

  "bank": {
    "table_code": "銀行代碼",
    "bank_name": "銀行名稱",
    "bank_user": "銀行戶名",
    "bank_number": "銀行帳號",
    "bank_book": "銀行存摺",
    "choose_transferbank_select": "--- 請選擇轉帳銀行 ---",
    "choose_transferbank_feedback": "請選擇轉帳銀行",
    "transfer_bank": "轉帳銀行",
    "transfer_bank_info": "欲更新銀行資料，請洽客服。戶名請與註冊姓名 / 身份證姓名相同，託售需實名認證 ，如不相同則不予以託售 (戶名不可更換)",
    "bank_transfer_info": "戶名請與註冊姓名 / 身份證姓名相同，託售需實名認證 ，如不相同則不予以託售 (戶名不可更換)欲更新銀行資料，請洽客服",
    "only_bind_bank": "僅接受綁定銀行轉入之款項",
    "bind_bank": "綁定銀行",
    "bankcard_info": "銀行卡資料",
    "bank_branch": "銀行分行",
    "bank_branch_open": "開戶分行",
    "choose_bankcard_select": "--- 請選擇銀行卡 ---",
    "bind_bank_account": "綁定銀行帳號",
    "bank_card_info": "銀行存簿/卡資料（請輸入要綁定的資訊，一旦綁定所有託售將自動匯至此銀行存簿/卡），綁定前請詳閱上傳範例。",
    "plz_accurately_info": "銀行卡綁定主要用於會員託售使用。為了您的託售能處理派發，請準確填寫辦理銀行卡時的開戶信息。",
    "bankuser_cannot_over": "銀行戶名長度不能超過50字元",
    "bankuser_cannot_required": "銀行戶名不能為空或者格式錯誤",
    "plz_select_bank": "請選擇銀行",
    "bank_number_required": "銀行帳號不能為空或者格式錯誤",
    "bank_branch_required": "開戶分行不能為空或者格式錯誤",
    "plz_transfer_bank": "請務必依照指定的銀行帳號進行轉帳，",
    "transfer_oldbank_not_check": "若轉至舊有的銀行帳號將無法查收",
    "not_responsible": "，恕不負責。",
    "the_website": "本站",
    "not_atm_card_cash": "不接受ATM無卡現金儲值的方式",
    "plz_transfer_remittance": "，請務必使用轉帳匯款，以免耽誤您的寶貴時間。",
  },

  "transfer": {
    "third_atm": "轉帳",
    "user": "轉帳帳戶",
    "account": "轉帳帳號",
    "submit_after": "轉帳後再提交",
    "linepay_tip": "※請先截圖QR Code 後再前往 Line 好友轉帳",
    "jkopay_tip": "※請先截圖QR Code後再前往街口支付App掃描轉帳",
    "qrcode_tip": "※請先截圖QR Code後再前往 App掃描轉帳",
    "already": "我已轉帳",
    "been_transferred": "我已匯款",
    "virtual_address": "轉帳虛擬貨幣地址",
    "only_virtual_address": "僅接受綁定虛擬貨幣地址轉入之款項",
    "point_records": "轉點紀錄",
    "no_turning_point": "無轉點紀錄！",
    "turnaround_time": "轉點時間",
    "iwant": "我想從",
    "goto": "轉點到",
    "enterpoint": "請輸入轉換點數",
    "onlyInteger": "請輸入轉換點數，只能輸入整數",
    "turn_point": "進行轉點",
    "points_records": "點數轉換",
    "turning_point": "贈轉點數",
    "turning_coin_record": "贈轉紀錄",
    "turning_coin": "贈轉金幣",
    "have_coin": "持有金幣",
    "turning_reduce_fees": "贈轉需扣除手續費",
    "maximum_coin": "單次贈轉最大點數",
    "minimum_coin": "單次贈轉最小點數",
    "donate_point_member": "會員贈點",
    "donate_point_account": "贈點帳號",
    "donate_points": "贈點點數",
    "donate_points_limit": "贈點點數需要介於",
    "created_at_transfer": "贈點時間",
    "transfer_mode": "贈點方式",
  },

  "stroe": {
    "store_barcode": "超商條碼",
    "store_ibon": "7-11超商",
    "store_familys": "全家超商",
    "store_ok": "OK超商",
    "store_hilife": "萊爾富超商",
    "third_store": "超商繳費",
    "plz_save_store_receipt": "超商繳費請繳費後，保存收據，並清晰拍照上傳，若無法提供收據將不予補點。",
    "store_name": "超商名稱",
    "store_code": "超商代碼",
  },

  "category_0": "HOT",
  "category_999": "捕魚",

  "customer": {
    "online_hi": "HI~有什麼能為您服務？",
    "h24": "24H",
    "h24_service": "二十四小時線上諮詢為您服務",
    "service": "客服",
    "service_contact": "客服",
    "service_line": "LINE客服",
    "line_service": "客服LINE",
    "contact_us": "聯繫客服",
    "uh_oh": "UH-OH!",
    "plz_contact_us": "請聯絡客服",
    "online_howru": "請加客服LINE",
    "service_tw": "線上客服",
    "service_en": "Online Service",
    "contact_service_center": "如有任何問題，歡迎聯繫客服中心",
    "service_profile_info": "已提供過的個人資料將無法自行異動。若需異動個人資料，請洽線上客服。",
    "service_contact_upload": "本網站正進行系統維護，造成您任何不便敬請見諒。如有任何問題，歡迎聯繫客服中心",
    "telegram": "Telegram",
    "whatsapp": "WhatsApp",
    "facebook": "Facebook",
    "instagram": "Instagram",
    "twitter": "Twitter",
    "maintenance_title1": "官",
    "maintenance_title2": "網",
    "maintenance_title3": "系",
    "maintenance_title4": "統",
    "maintenance_title5": "維",
    "maintenance_title6": "護",
    "maintenance_title7": "中",
  },

  "copy": {
    "friend_copy": "立刻複製你的推廣網址交給你的好友吧！",
    "friend_promotion_url": "你的專屬推廣網址，複製以下文字發送給好友",
    "copy_Succe": "複製成功",
    "copy_error": "複製失敗",
  },

  "upload": {
    "receipt": "上傳收據",
    "not_receipt": "未上傳收據",
    "tip_prompt_sample": "上傳圖片範例",
    "tip_digit_sample": "數位存簿上傳範例",
    "identity_title": "身份證上傳範例",
    "identity_main_content": "當您選擇拍照或是上傳身分證(儲值前必須要上傳驗證)時，請注意以下幾點:", "tip_digit_content": "綁定數位帳戶需提供數位帳戶資訊(一張)、提款卡+身分證(放一起拍一張)，共兩張分別上傳，提供上傳圖片時，請注意以下幾點：",
    "tip_digit_content": "當您選擇拍照或是掃描上傳身分證 / 銀行存摺時，請注意以下幾點：",
    "tip_entity_sample": "實體存簿上傳範例",
    "tip_entity_content": "當您選擇拍照或是上傳銀行存摺/身分證(非必要上傳，依本平台需要，客服會主動與會員索取，例如:有同名同姓之會員等..)時，請注意以下幾點：",
    "tip_prompt_content": "當您選擇拍照或是掃描上傳身分證 / 銀行存摺時，請注意以下幾點：",
    "upload_exp": "上傳範例",
    "plz_upload_pic": "請上傳照片",
    "only_img_upload": "只允許上傳圖片，",
    "not_pic": "不是圖片格式",
    "only_three_pic": "最多只能上傳三張圖片",
    "plz_upload_profile": "請上傳【個人名片】",
    "plz_jkopay_profile": "請上傳街口個人名片",
    "upload_limit_over": "超過可上傳檔案容量限制",
    "plz_number_profile": "請上傳身份證",
    "plz_upload_profile_front": "請上傳身份證【正面】",
    "plz_upload_profile_reverse": "請上傳身份證【反面】",
    "plz_upload_bank_passbook": "請上傳實體存簿照片",
    "plz_upload_id_card": "請上傳數位存簿照片",
  },

  "pw": {
    "plz_enter_safe_code": "請輸入安全密碼",
    "safe_code": "安全密碼",
    "forgot": "忘記密碼",
    "forgot_mark": "忘記密碼?",
    "ButSendCode_newpw": "發送新密碼",
    "ButSendCode_reset": "秒後重新發送",
    "tip_idpw_not_same": "會員帳號不可與密碼相同",
    "password": "密碼",
    "confirmation": "確認密碼",
    "safe_pw_feedback": "安全密碼格式錯誤",
    "user_pw": "使用者密碼",
    "user_chgpw": "變更密碼",
    "chgsafepw": "變更安全密碼",
    "old_pw": "原密碼",
    "old_pw_feedback": "原密碼格式錯誤，輸入限制 6 - 20 字元",
    "new_pw": "新密碼",
    "new_pw_feedback": "新密碼格式錯誤，輸入限制 6 - 20 字元",
    "new_pw_again": "再次輸入新密碼",
    "new_pw_again_feedback": "麻煩再填一次確認密碼",
    "set_safe_pw": "設定安全密碼",
    "edit_safe_pw": "修改安全密碼",
    "old_safe_pw": "原安全密碼",
    "old_safe_pw_feedback": "原安全密碼格式錯誤",
    "new_safe_pw": "新安全密碼",
    "new_safe_pw_feedback": "新安全密碼格式錯誤"
  },

  "vip": {
    "vip_freeback": "VIP回饋",
    "vip_info": "VIP資訊",
    "vip_title": "VIP",
  },

  "virtual": {
    "buycoin_view": "買幣快速訪問",
    "channel": "類型",
    "name": "名稱",
    "bitcoin": "虛擬幣",
    "account": "虛擬帳號",
    "currency": "虛擬貨幣",
    "tied_crypto": "綁定虛擬貨幣",
    "currency_title": "虛擬貨幣地址資料",
    "virtual_address": "託售的虛擬貨幣地址",
    "choose_address_select": "--- 請選擇虛擬貨幣地址 ---",
    "choose_address_feedback": "請選擇進行託售的虛擬貨幣地址",
    "currency_limit": "虛擬點數需要介於",
    "currency_coin_limit": "虛擬幣需要介於",
    "treasure_currency": "託售虛擬幣",
    "treasure_currency_limit": "託售虛擬幣需要介於",
    "plz_treasure_currency": "請輸入託售虛擬幣",
    "plz_input_name": "請輸入錢包名稱",
    "plz_select_channel": "請選擇錢包地址的類型",
    "plz_upload_profile": "請上傳【錢包地址】",
    "wallet_address": "錢包地址",
    "exchangeRate": "匯率",
    "exchangeRatePoint": "兌換點數",
    "pay": "虛擬幣支付",
  },

  "pay": {
    "payment": "數位支付",
    "proceed": "前往支付",
    "qrcode": "支付二维碼",
    "tide_digital": "綁定數位支付",
    "linepay_account": "一卡通Money帳號",
    "jkopay_account": "街口支付帳號",
    "mobile_pay_info": "行動支付資料（若需異動支付資料，請洽線上客服）",
    "mobile_wallet_info": "行動支付資料（請輸入要綁定的資訊，一旦綁定所有提款將自動匯至此行動錢包）",
    "linepay": "一卡通Money支付",
    "mobile_pay_required": "行動支付帳號不能為空或者格式錯誤",
    "third": "三方支付優惠",
    "icash_pay_account": "icash支付帳號",
    "px_pay_account": "綁定電話號碼",
    "plz_linepay_profile": "請上傳一卡通Money帳號",
    "plz_upload_linepay": "請上傳【一卡通Money帳號封面】",
    "plz_upload_icashpay": "請上傳【icash帳號封面】",
    "plz_upload_pxpay": "請上傳【全支付帳號封面】",
  },

  "user": {
    "join": "加入會員",
    "join_agent": "經銷商註冊",
    "agent": "經銷商",
    "agent_account": "經銷帳號",
    "id": "帳號",
    "account": "帳號",
    "password": "密碼",
    "password_confirmation": "確認密碼",
    "email": "信箱",
    "name": "姓名",
    "w_code": "安全密碼",
    "birthday": "生日",
    "nickname": "暱稱",
    "realname": "真實姓名",
    "user_email": "電子信箱",
    "coin": "餘額",
    "mobile": "電話",
    "line_id": "Line ID",
    "telegram_id": "Telegram ID",
    "password_notice": "密碼限制 6 - 20 字元，只能使用英數字不可與帳號相同",
    "password_confirmation_notice": "請再次輸入密碼",
    "name_notice": "請輸入您的真實姓名，註冊後無法更改",
    "line_id_notice":"請輸入您的LINE ID，註冊後無法更改",
    "user_total_bet": "總投注",
    "enter_error_feedback": "輸入錯誤",
    "user_data": "會員資料",
    "profile": "會員中心",
    "user_account": "會員帳號",
    "user_id": "使用者名稱",
    "target_account": "對象帳號",
    "phone_number": "手機號碼",
    "required": "不能為空或者格式錯誤",
    "captcha": "驗證碼",
    "captcha_required": "驗證碼不能為空，請重新輸入",
    "agree": "我已年滿18歲並同意投注相關規範以及",
    "terms": "服務條款",
    "submit": "確認提交",
    "verify_btn": "發送驗證",
    "account_notice": "帳號限制 6 - {max} 字元，只能使用英數字",
    "name_notice": "請輸入您的真實姓名，註冊後無法更改",
    "id_group612": "英數組合至少 6-12個字元長(注意英文大小寫)",
    "id_group620": "英數組合至少 6-20個字元長(注意英文大小寫)",
    "rememberme": "記住我",
    "tip_idpw_not_same": "會員帳號不可與密碼相同",
    "tip_phone_captcha_first": "請先發送手機驗證碼",
    "tip_plz_agree": "請先同意相關規範以及服務條款",
    "tip_id_ok": "帳號已經檢查成功",
    "tip_phone_captcha_send": "手機驗證碼已發送",
    "ButSendMobileCode_second_reset": "秒後重新發送",
    "ButSendMobileCode_captcha_send": "發送驗證",
    "join_description": "",
    "login_description": "",
    "forgot_description": "",
    "whats_app": "WhatsApp",
    "registered_mobile": "請輸入註冊的手機號碼",
    "line_login": "Line 登入",
    "area_code": "電話區碼"
  },

  "placeholder": {
    "agent": "請輸入經銷商",
    "account": "請輸入帳號",
    "captcha": "請輸入驗證碼",
    "email": "請輸入信箱",
    "line_id": "請輸入Line ID",
    "mobile": "請輸入電話",
    "name": "請輸入姓名",
    "nickname": "請輸入暱稱",
    "password": "請輸入密碼",
    "password_confirmation": "請再次輸入密碼",
    "telegram_id": "請輸入Telegram ID",
    "whats_app": "請輸入WhatsApp",
  },

  "dialogRule_title": "條款與條件",
  "dialogRule_list": [
    {
      "title": "規則條款",
      "cont1": "您瞭解您註冊成為會員後，即可使用本公司所提供之各項服務（以下稱本服務）。"
    },
    {
      "title": "遵守會員規範及法律規定",
      "cont1": "當會員使用本服務時，即表示除了同意遵守本服務條款外，還同意接受本公司對會員規範（包括各項遊戲規則、球賽投注規則、公告及注意事項等）及相關法令規定之拘束，本公司所有產品與客戶服務、軟件系統、網絡架構等相關業務事宜，皆由菲律賓娛樂和博彩認證機構 Philippine Amusement and Gaming Corporation 所授權以及監管，使用本服務之會員請遵守使用者當地法令之許可，如有違反之情事恕非本服務之負責範圍。"
    },
    {
      "title": "服務簡介",
      "cont1": "本公司旨在創造一個『安全可靠』、『即時便利』、『公平公正』、『專業營運』的優質娛樂服務平台，強調的是讓會員不受時空的限制，24小時隨時上線就可以參與一個公平公正的遊戲，亦可以享受到與世界各地玩家切磋的樂趣，我們秉持以客為尊的態度，不斷開發創新及了解客戶需求是本公司引以為傲的經營理念，冀望能創造出嶄新的娛樂價值以及達到多方普及的目的，成為具有領導指標性的娛樂網站。",
      "cont2": "本公司提供網路金流付費平台，你所購買的點數將可享受符合本公司平台的服務。若您使用虛偽不正之方式進行「儲值」,本公司也將保留隨時終止您會員資格及使用各項服務之權利。"
    },
    {
      "title": "真實登錄義務",
      "cont1": "基於本公司所提供之各項服務，您同意於註冊時依註冊申請程序所提示之項目，登錄您本人正確、真實及完整之個人資料；當您的個人資料有異動時，請立即更新，以維持您個人資料之正確、真實及完整。",
      "cont2": "如因您登錄不實資料或冒用他人名義以致於侵害他人之權利或違法時，應自負法律責任；並同意您所提供之個人資料不實或個人資料有異動但沒有更新以致於與原登錄之資料不符時，本公司有權隨時終止您的會員資格及使用各項會員服務之權利。"
    },
    {
      "title": "服務停止與更改",
      "cont1": "• 會員登錄之資料不實。",
      "cont2": "• 使用他人的名義申請本服務。",
      "cont3": "• 違反遊戲公平原則。",
      "cont4": "• 參與遊戲或比賽時，故意鑽研系統漏洞或利用遊戲弱點以影響結果。",
      "cont5": "• 違反本服務條款時。",
      "cont6": "• 本公司不允許不公平的下注方式、雙邊下注、無風險下注及任何無風險解凍之下注方式，如有發現上述問題之玩家，本公司有終止玩家帳號使用之權利。如有任何爭議問題，本公司有最終決定權。",
      "cont7": "• 無論任何情形，就停止或更改服務或終止會員帳戶服務所可能產生之困擾、不便或損害，本服務對任何會員或第三人均不負任何責任。"
    },
    {
      "title": "服務暫停或中斷",
      "cont1": "本公司於下列情形之一時，得暫停或中斷本服務之全部或一部，對於使用者不負擔任何賠償責任：",
      "cont2": "• 對於本服務相關系統設備進行遷移、更換或維護時。",
      "cont3": "• 因不可歸責於本公司所造成服務停止或中斷。",
      "cont4": "• 因不可抗力所造成服務停止或中斷",
      "cont5": "如因本公司對於本網站相關系統設備進行遷移、更換或維護而必須暫停或中斷全部或一部之服務時，本公司於暫停或中斷前將以電子郵件通知或於本網站上公告。",
      "cont6": "本公司不允許不公平的下注方式、雙邊下注、無風險下注及任何無風險解凍之下注方式，如有發現上述問題之玩家，本公司有終止玩家帳號使用之權利。如有任何爭議問題，本公司有最終決定權。",
      "cont7": "對於本服務之暫停或中斷，可能造成您使用上的不便、資料遺失或其他經濟及時間上之損失，您平時應採取適當的防護措施，以保障您的權益。"
    },
    {
      "title": "保管義務",
      "cont1": "會員有義務妥善保管在本網站之帳號與密碼，並為此組帳號與密碼登入系統後所進行之一切活動負責。",
      "cont2": "為維護會員自身權益，請勿將帳號與密碼洩露或提供予第三人知悉，或出借或轉讓他人使用。"
    },
    {
      "title": "同意各項球賽及遊戲規則",
      "cont1": "為避免使用者於本平台投注球賽或遊戲時產生爭議，各項規則於遊戲中心和球賽中心均有詳細說明，請務必詳細閱讀本服務所定之各項規則。",
      "cont2": "會員一經開始使用本服務，即被視為已接受所有之規定。"
    },
    {
      "title": "會員規範之增訂及修改",
      "cont1": "本服務條款如有增訂或修改，您同意自該修訂條款於本網站公告之時起受其拘束，本網站將不對會員個別通知。",
      "cont2": "如您於公告後繼續使用本服務，則視為您已經同意該修訂條款。"
    },
    {
      "title": "隱私聲明",
      "cont1": "我們致力為客戶保護隱私並提供一個最安全的遊戲平臺，我們在此網站搜集的資料將會為您提供最卓越的服務，我們不會出賣或租賃您的個人資料給第三方。",
      "cont2": "客戶所提供的個人資料均經過SSL128加密技術處理，並儲存在安全的、非公開的作業系統，對於有機會接觸客戶的個人資料的協助夥伴也必需遵守我們訂立的隱私保密規則。"
    },
    {
      "title": "免責聲明",
      "cont1": "部份地區或國家法律尚未明定線上博彩的合法性問題，甚至某些地區或國家已明確規範線上博彩為非法行為。我們無意邀請任何人在這些地區或國家非法使用本服務平台。",
      "cont2": "使用者必需確定在您所居住的地區或國家使用線上博彩是否合法，並負完全的責任，使用本服務之會員請遵守使用者當地法令之許可，如有違反之情事恕非本公司之負責範圍。"
    }
  ],

  "event":{
    "activity_description":"活動說明",
    "activity_methods":"活動辦法",
    "remain_times":"剩餘次數",
    "login":"請先登入會員",
    "event_period_not_started_or_hasended":"當前優惠活動已過期",
    "no_corresponding_flag":"無相對應的會員旗",
    "not_in_accountGroup":"該會員組不符合抽獎資格",
    "iinvalid_discount_setting":"優惠設定不是啟用狀態",
    "empty_slices":"獎項不存在",
    "out_of_qualification":"不符合參加資格",
    "out_of_draws_times":"無剩餘抽獎次數",
    "checked_finish":"已完成今日簽到",
    "checked_over":"已完成所有簽到",
    "no_data":"活動暫無開放 敬請期待",
    "sign":"簽到",
    "qualifiy":"不符合參與資格",
    "slice_name":"獎項",
    "amount":"點數",
    "darwReport":"抽獎紀錄",
    "searchDarwReport":"查看抽獎紀錄",
  }
}
