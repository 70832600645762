export default {
	"help_gambling_tips": "Gambling Tips",

	"auto_transfer": "auto transfer",
	"bind": "bind",
	"bind_line": "Bind Line",
	"has_registered_account": "Is there an account already registered?",
	"no": "no",
	"unbind": "unbind",
	"yes": "yes",

	"withdraw_password": "Secure password",
	"plz_withdraw_password": "Enter secure password",
	"tip_withdraw_password": "Enter secure password",
	"forgot_safe_password": "Forgot secure password",
	"forgot_safe_password_mark": "Forgot secure password?",
	"send_safe_password": "Send secure password",
	"send_safe_password_reset": "Send again in a sec",

	"bind_0": "unbound",
	"bind_1": "has bound",
	"status_0": "प्रोसेस हो रहा है",
	"status_1": "पूर्ण हैंडलिंग",
	"status_2": "अस्वीकृत",
	"stroe_IBONS": "7-11",
	"stroe_FAMILYS": "Familys",
	"stroe_HILIFE": "Hi-Life",
	"stroe_OK": "OK Mart",
	"stroe_OKMART": "OK Mart",
	"pay_type_BankTransfer": "बैंक खाते में ट्रांसफर करेंl",
	"pay_type_LinePay": "iPASS Money",
	"pay_type_JkoPay": "JKOpay",
	"pay_type_IcashPay": "icash Pay",
	'pay_type_PxPay': "PX Pay",
	"pay_type_Virtual": "क्रिप्टोक्यूरेंसी भुगतान",
	"pay_type_ThirdParty": "Payment",
	"pay_type_ThirdParty_1": "बैंक खाते में ट्रांसफर करेंl",
	"pay_type_ThirdParty_2": "iPASS Money",
	"pay_type_ThirdParty_4": "JKOpay",
	"pay_type_ThirdParty_8": "सुविधा स्टोर में भुगतान करें",
	"pay_type_ThirdParty_16": "क्यूआर कोड",
	"pay_type_ThirdParty_32": "एटीएम स्थानांतरण",
	"pay_type_ThirdParty_64": "ऑनलाइन बैंकिंग",
	"pay_type_ThirdParty_128": "Credit card",
	"pay_type_ThirdParty_256": "Paytm",
	"pay_type_ThirdParty_512": "UPI",
	"pay_type_ThirdParty_1024": "GCash",
	"pay_type_ThirdParty_2048": "PayMaya",
	"pay_type_ThirdParty_4096": "क्रिप्टोक्यूरेंसी भुगतान",
	"pay_type_ThirdParty_8192": "Quick Payment",
	"pay_type_ThirdParty_16384": "Quick Scan",
	"pay_type_ThirdParty_32768": "EPay",
	"pay_type_8192": "Quick Payment",
	"pay_type_16384": "Quick Scan",
	"pay_type_32768": "EPay",

	"all": "संपूर्ण गेम्स",
	"all_games": "सभी खेल",
	"all_activity": "सभी प्रस्ताव",
	"audio": "audio",
	"bind_bankcard": "कृपया बैंक कार्ड के साथ बंधे",
	"bind_jkopay": "Please go tied with the JkoPay",
	"bind_linepay": "Please go tied with the iPASS money",
	"bind_icashpay": "Please go tied with the IcashPay",
	"bind_pxpay": "Please go tied with the PxPay",
	"bind_virtualwallet": "Please go tied with the cryptocurrency wallet address",
	"bind_usercard": "कृपया आईडी कार्ड के साथ जुड़ने के लिए आगे बढ़ें",
	"confirm": "confirm",
	"confirm_cancel_order": "confirm cancel order?",
	"dont_discount": "छूट का उपयोग नहीं",
	"examination_passed": "बीतने के",
	"landscape": "Turn your phone, thanks.",
	"not_approved": "not approved",
	"under_review": "समीक्षा कर रहे हैं",
	"review_returns": "review returns",
	"virtual_currency_channel": "Virtual currency channel",

	"details": "विवरण",
	"illustrate": "अनुदेश",
	"tab_third_upi": "UPI",
	"tab_third_gcash": "GCash",
	"tab_third_paymaya": "PayMaya",
	"tab_third_quick_payment": "Quick Payment",
	"tab_third_quick_scan": "Quick Scan",
	"tab_third_epay": "Epay",
	"deposit_payupi": "UPI ID",
	"deposit_plz_enter_upi": "कृपया दर्ज करें UPI ID",
	"header_top_qa": "How to Deposit?",
	"header_top_line": "Service",
	"header_top_info": "FAQ",

	"tip": "अनुस्मारक।",
	"announcement": "संदेश",
	"system_title": "नवीनतम समाचार",
	"announcement_type": "नोटिस वर्गीकृत करें",
	"item": "Item",
	"all_item": "ALL",
	"all_item_a": "सिस्टम नोटिस",
	"all_item_b": "प्रमोशन",
	"now": "पृष्ठ में",
	"page": " ",
	"nomsg": "कोई सूचना नहीं",
	"type": "Type",
	"address": "Address",
	"status": "Status",
	"soon1": "Coming",
	"soon2": "Soon",
	"soon3": "",
	"soon4": "",
	"game_strategy":"Gaming strategy",
	"member_appendLink":"alliance",

	"order_id": "Txn. ID",
	"created_at": "जमा समय ",
	"status_name": "Status",
	"fields_complete_at": "समाप्त समय",
	"fields_demand": "लाइन उत्पादन की मांग",
	"fields_valid": "प्रभावी लाइन उत्पादन",
	"retreat": "रीबेट रिकॉर्ड",
	"total": "कुल",
	"odd_number": "विषम संख्या",
	"single_number": "बीमा आईडी ",
	"beting": "Betting Amount",
	"amount_bet": "Betting Amount",
	"vaild_bet": "मान्य दांव",
	"content_bet": "सट्टेबाजी की सामग्री",
	"time_bet": "दांव समय",
	"winlose": "हार/जीत",
	"platform_from": "मंच से बाहर स्थानांतरण",
	"platform_to": "प्लेटफार्म में स्थानांतरण",
	"points": "अंक",
	"friend_num": "कुल रेफरल ",
	"friend_list": "रेफरल सारांश",
	"plz_select_friend": "Please select",
	"amount_share": "रिबेट",
	"first": "प्रथम स्तर",
	"second": "दूसरा स्तर",
	"valid_first": "प्रथम स्तर की वैध शर्त",
	"valid_second": "दूसरे स्तर का वैध दांव",
	"amount_share_first": "प्रथम स्तर की रिबेट",
	"amount_share_second": "दूसरे स्तर की रिबेट",
	"fee": "fee",
	"friend_shardmode": 'मित्र शेयर मोड, अपने दोस्तों को सलाह दें, हार या जीत कोई फर्क नहीं पड़ता, "1/1000" प्रतिक्रिया होगी',
	"friend_shard_unlimit": "असीमित मित्र राशि बांट रहे हैं, अधिक साझा करें, अधिक कमाएं",
	"friend_copy": "जल्दी करें! शामिल होने के लिए अपने रिश्तेदारों और दोस्तों को आमंत्रित करें!",
	"friend_promotion_url": "कॉपी कोड ",
	"shareReport": "View records",
	"hisbetReport": "गेम रिकॉर्ड",
	"user_hisSearch": "Records Search",
	"user_histransfer": "प्वाइंट सस्ता इतिहास",

	"money_transfer": "बैंक खाते में ट्रांसफर करेंl",
	"credit_card": "क्रेडिट कार्ड",
	"paytm": "Paytm",
	"ATM": "ATM",
	"webatm": "ऑनलाइन बैंकिंग",
	"third_atm": "एटीएम स्थानांतरण",
	"third_qrcode": "क्यूआर कोड",
	"linepay": "iPASS Money",
	"linepaymoney": "iPASS Money",
	"jkopay": "JKOpay",
	"icashpay": "icashPay",
	"pxpay": "PxPay",
	"payItem_6_subtitle": "औसत प्राप्त बिंदु लगभग १ मिनट 1",
	"ibon_pay": "7-11 आइबन प्रिंट और भुगतान करें",
	"famiport_pay": "FamiPort प्रिंट करें और भुगतान करें",
	"eighty_seven_percent": "87% लोग हैं",
	"choose_this_option": "इस तरह इस्तेमाल कर रहे हैं",
	"start": "जमा करें",
	"downloadPic": "डाउनलोड QRcode",
	"fields_account": "लेखा आइटम",
	"fields_about": "सम्बंधित जानकारी",
	"table_order_date": "आदेश की तारीख",
	"table_expire_date": "भुगतान की समय सीमा",

	"no_data": "कोई सूचना नहीं",
	"no_record": "कोई सूचना नहीं",
	"login_invalid": "लॉग इन फेल, कृपया दोबारा लॉग इन करें!",
	"error_sorry": "Sorry, error pages.",
	"error_back_home": "वापस होम पेज पर",
	"back": "वापसी",
	"search": "खोजें",
	"operating": "संचालन",
	"uphold_ing": "मेंटेनेन्स के तहत",
	"hotpro_tip": "More",
	"hotpro_title": "Product",
	"hotpro_menu": "सेटिंग",
	"hotpro_menu2": "सेटिंग",
	"hotpro_hello": ", नमस्ते",
	"hotpro_switch": "On Left",
	"profile": "प्रोफाइल",
	"hisBet": "सट्टे का रिकॉर्ड।",
	"home": "होम ",
	"just8899": "just8899",
	"jackpot539": "539",
	"activity": "प्रमोशन पोस्टिंग।",
	"share": "एफिलिएट बने",
	"card_manage": "कार्ड के साथ बंधे",
	"bind_limit": "Has been the limited",
	"card_bind_limit": "बंधे कार्ड के लिए सीमित किया गया है",
	"card_bind_view": "बंधे कार्ड की जाँच करें",
	"bind_view": "View",
	"receiptview": "नुस्खा की जाँच करें",
	"web_msg": "संदेश",
	"web_mail": "संदेश",

	"sport": "Sports",
	"live": "Lives",
	"egame": "E-Game",
	"lotto": "Lottery",
	"chess": "Chess",
	"fish": "Fish",
	"animal": "Animal",

	"game_sport": "Sports",
	"game_live": "Lives",
	"game_egame": "E-Game",
	"game_lotto": "Lottery",
	"game_chess": "Chess",
	"game_fish": "Fish",
	"game_animal": "Animal",

	"s_hot": "Hot",
	"s_sport": "Sports",
	"s_live": "Lives",
	"s_egame": "E-Game",
	"s_lotto": "Lottery",
	"s_chess": "Chess",
	"s_animal": "Animal",
	"s_fish": "Fish",
	"s_slot": "Slot",
	"s_slot2": "Slot",
	"s_together": "Multiple",
	"s_jackpot": "Jackpot",

	"blog": "Blog",
	"jackpot": "Jackpot",
	"jackpot_list": "",
	"jackpot_in": "",
	"jackpot_geting": "",
	"jackpot_get": "Get",
	"jackpot_dollar": "point",
	"recommend": "Recommed",
	"s_desopit": "Deposit",
	"s_turning": "कोटा ट्रांसफर",
	"s_coin": "Give points",
	"s_wallet": "मुख्य बटुआ ",
	"s_share": "संबद्ध कार्यक्रम",

	"user_approve": "Authentication",
	"bind_data": "Tied info",
	"learn_more": "अधिक",
	"share_money": "छूट साझा करना",
	"help_video": "Video Describe",
	"help_question": "FAQ",
	"help_download": "APP Download",
	"help_download_ios": "IOS Download",
	"help_download_and": "android Download",
	"help_download_only": "Download",
	"help_novice": "Novice Help",
	"help_game": "Help Center",
	"help_check": "Clear cache",
	"help_newspaper": "Casino News",
	"help_champion": "539",
	"help_comp": "Corporate Affairs",
	"game_type": "खेलों को वर्गीकृत करें",
	"game_platform": "प्रदाता ",
	"precautions": "Notice",

	"footer_declare": `वेबसाइट पर "गेम पॉइंट्स" केवल मनोरंजन के लिए हैं, नकद के साथ अंक नहीं देख सकते हैं और बोनस उत्पादों को बदल सकते हैं।`,
	"footer01_title": "सरल प्रक्रिया",
	"footer01_sub": "3 मिनट जमा करें और निकालें",
	"footer02_title": "उत्पाद सेवा",
	"footer02_sub": "सबसे प्रचुर मनोरंजन",
	"footer03_title": "व्यक्तिगत इंटरफ़ेस",
	"footer03_sub": "पीछा अनुभव उपयोगकर्ता सर्वोच्च",
	"footer04_title": "पेशेवर ब्रांड",
	"footer04_sub": "विश्वास के लायक कंपनी की विश्वसनीयता",
	"footer05_title": "उपयोगकर्ता प्रशंसा",
	"footer05_sub": "उपयोगकर्ता प्रशंसा अनुशंसा",
	"partner": "पार्टनर",
	"using_help": "मदद समर्थन",
	"info_help": "मदद समर्थन",
	"allday_service": "24 घंटे सेवा की जानकारी",
	"phone_bet": "सेलफोन सट्टेबाजी",
	"ios": "IOS",
	"android": "Android",

	"wallets": "मुख्य बटुआ",
	"DG": "DG",
	"AB": "AB",
	"SA": "SA",
	"WM": "WM",
	"SUPER": "SUPER",
	"101": "101",
	"RG": "RG",
	"BWIN": "BWIN",
	"KA": "KA",
	"fish_GR": "GR fish",
	"fish_BWIN": "BWIN fish",
	"fish_OB": "PM fish",
	"fish_ZG": "ZG fish",
	"fish_RSG": "RSG fish",
	"hot_allbet": "AB live",
	"hot_DG": "DG live",
	"hot_OB": "PM live",
	"hot_WG": "WG lotto",
	"hot_ZG": "ZG slot",
	"hot_OB_sport": "PM sport",
	"hot_OB_game": "PM slot",
	"hot_WG": "WG lotto",
	"hot_RSG": "RSG slot",
	"hot_BOBHH": "BOBHH chess",

	"uphold_time": "मेंटेनेन्स की जानकारी।",
	"maintain_list": [
		{
			"title": "Allbet",
			"date": "सोमवार",
			"time": "08:00~12:00"
		},
		{
			"title": "SA",
			"date": "सोमवार",
			"time": "11:00~13:30"
		},
		{
			"title": "WM",
			"date": "सोमवार",
			"time": "11:00~13:30"
		},
		{
			"title": "SUPER",
			"date": "सोमवार",
			"time": "12:30~16:00"
		},
		{
			"title": "DG",
			"date": "बुधवार",
			"time": "06:30~10:30"
		}
	],

	"normal": "Normal",
	"stop_using": "अक्षम",
	"today": "आज",
	"yesterday": "कल",
	"lastweek": "पिछले सप्ताह",
	"thisweek": "इस सप्ताह",
	"lastmonth": "पिछले महीने",
	"thismonth": "इस महीने",
	"startdate": "समय शुरू",
	"deadline": "अंत समय",
	"platform": "खेल का प्रकार/खेल मंच",
	"plz_select_feedback": "कृपया चुनें।",
	"account_number": "आईडी कार्ड नंबर",
	"plz_account_number": "कृपया आईडी कार्ड नंबर दर्ज करें",
	"dateValid":"पूछताछ का समय एक माह के भीतर है",

	"no_operating_authority": "यह फ़ंक्शन अधिकृत नहीं है!",
	"failed_request_plz_try_again": "अनुरोध विफल रहा, कृपया बाद में पुन: प्रयास करें",
	"upload_limit_over": "फ़ाइल अपलोड करने की सीमा से अधिक",
	"click_bank_name_copy": "※वार्म रिमाइंडर: क्लिक करें<b><u>बैंक खाता नंबर</u></b>और<b><u>खाते का नाम</u></b> अब कॉपी कर सकते हैं",
	"fill_field": "कृपया इस क्षेत्र को भरें",
	"fill_field_correctly": "कृपया इस क्षेत्र को सही ढंग से भरें",
	"plz_enter_username": "प्लेयर आईडी",
	"plz_enter_coin_num": "कृपया सिक्कों की राशि दर्ज करें",
	"plz_enter_donate_points": "कृपया अंक खाता संख्या दर्ज करें",
	"plz_enter_points_num": "कृपया अंक दर्ज करें",
	"plz_enter_user_mobile": "कृपया सेल फ़ोन नंबर के साथ प्लेयर आईडी दर्ज करें",
	"plz_enter_CAPTCHA": "सत्यापन कोड दर्ज करें",
	"plz_linpay_account": "Please enter iPASS money account.",
	"plz_jkopay_account": "कृपया जेकेओ पे अकाउंट दर्ज करें",
	"plz_icashpay_account": "Please enter icashPay account.",
	"plz_pxpay_account": "Please enter PxPay account.",
	"gift_coins": "दिए गए सिक्कों की मात्रा",

	"tip_captchas_required": "खाली या गलत प्रारूप नहीं हो सकता",
	"tip_donate_cant_required": "खाली या गलत प्रारूप नहीं हो सकता",
	"tip_cant_required": "खाली या प्रारूप त्रुटि",
	"tip_cant_captcha_required": "आपके द्वारा दर्ज किया गया सत्यापन कोड गलत है, कृपया पुनः प्रयास करें। ",
	"get_agree": "मेरी आयु कम से कम 18 वर्ष है और मैंने सभी नियमों और शर्तों को पढ़ और स्वीकार कर लिया हैl",
	"i_have_read": "मैंने निम्नलिखित पढ़ा है:",
	"terms": "नियम और शर्तें",
	"tip_mobile_captcha_first": "कृपया पहले सेलफोन सत्यापन कोड भेजें",
	"tip_plz_agree": "कृपया संबंधित नियमों और सेवा शर्तों से सहमत हों",
	"tip_id_ok": "प्लेयर प्रारंभिक अवस्था में नहीं है।",
	"tip_mobile_captcha_send": "मोबाइल प्रमाणीकरण कोड आपको फ़ोन नंबर पर भेज दिया गया है",
	"tip_mobile_second_reset": "Send again in a sec",

	"dialogTipDigit_main_info": "कृपया पूरी जानकारी बनाए रखें, कोने, किनारों/प्रतिबिंब/धुंधलापन/व्यक्तिगत जानकारी को कवर न करें।",
	"how_light": "प्रतिबिंब से कैसे बचें?",
	"how_light_c1": "ऐसी जगह चुनें जहां तस्वीरें लेने के लिए पर्याप्त रोशनी हो।",
	"how_light_c2": "फ्लैश का प्रयोग न करें।",
	"how_light_c3": "प्रकाश के कारण होने वाले सफेद प्रतिबिंब से बचने के लिए आप जिस चीज का चित्र लेते हैं उसे सीधा बनाएं।",
	"how_blur": "धुंधलेपन से कैसे बचें?",
	"how_blur_c1": "तस्वीरें लेने से पहले फोकस करें, स्क्रीन, कैमरा क्लिक करें और शटर बटन को पुश करें।",
	"how_blur_c2": "चित्र लें या मूल स्कैन करें, मुद्रित नहीं।",
	"tip_ok_sample": "सटीक नमूना",
	"tip_ok_c1": "पहचान पत्र",
	"tip_ok_c2": "बैंक की किताब",
	"tip_exp": "संदर्भ उदाहरण",
	"tip_error_sample": "त्रुटि उदाहरण",
	"tip_error_c1": "कटे हुए कोने या किनारे",
	"tip_error_c2": "पहचान कोने को काटें",
	"tip_error_c3": "पहचान नहीं सकता",
	"tip_error_c4": "प्रतिबिंब के भाग",
	"tip_error_c5": "दाहिने तल पर प्रतिबिंब",
	"tip_error_c6": "कलंक",
	"dialogTipDigit_ok_c1": "पहचान पत्र",
	"dialogTipDigit_ok_c2": "बैंक की किताब",
	"dialogTipEntity_ok_c1": "पहचान पत्र",
	"dialogTipEntity_ok_c2": "बैंक की किताब",

	"bw8_li_01": "請在點數未上分前【保留您的繳費收據】，以保障您的權益。",
	"bw8_li_02": "如您是使用【信用卡】方式儲值，將【收取10%手續費】，舉例:您儲值1,000元，實際獲得的遊戲點數為900點。",
	"bw8_li_03": "單號成立後請務必於【2小時內】繳費入款 ，超過時限單號即失效，提交單號後若超過2小時，請再重新提交入款單。",
	"bw8_li_04": "每組儲值繳費單號為【單次繳費使用】請勿重複繳款。",
	"bw8_li_05": "儲值時請繳申請時之【正確】的金額，假設您輸入儲值5,000，繳款時請繳款5,000元整，避免導致未收到款項。",
	"bw8_li_06": "如您【先前已有申請儲值】，但是尚未完成交易之情形，無法再次申請儲值，如需儲值，請洽客服協助再次開單。",
	"bw8_li_07": "如您有申請託售，並在【託售未處理完畢前】，請勿再申請儲值以免造成您權益上的損失。",
	"bw8_li_08": "託售時須達到儲值金額【一倍洗碼量】方可進行託售，如未達洗碼標準將無法成功託售。",
	"bw8_li_09": "若利用本娛樂城進行任何洗錢詐騙行為，本娛樂城有權利永久終止會員服務，不另行通知。",
	"bw8_li_10": "【請確實填寫本人手機號碼】避免儲值管道要求手機驗證，系統偵測錯誤號碼導致通道關閉。",

	"casino_introduce": "Top Games",
	"hot": "उपयोगकर्ता प्रशंसा अनुशंसा",
	"play_together": "Multiple",
	"fighting": "Battle",
	"slot": "Slot",
	"slot2": "Slot",
	"fishing": "Fish",
	"together": "Multiple",

	"slot_description": " -स्लॉट गेम, स्लॉट गेम, स्लॉट मशीन टेबल, ऑनलाइन स्लॉट मशीन, स्लॉट मशीन, स्लॉट, गेम",
	"chess_description": "-ड्रैगन टाइगर, नियुनिउ, नियुनिउ, फ्राइड गोल्डन फ्लावर, ऑनलाइन बैकरेट, फैन टैन, कलर डिस्क, शतरंज और कार्ड गेम्स, शतरंज और कार्ड गेम्स",
	"live_description": "-ओबर, सैलून, डब्ल्यूएम, डीजी, लाइव बैकरेट, ऑनलाइन बैकरेट, बैकरेट गेम, बैकरेट मेक मनी, बैकरेट विन मनी",
	"lottery_description": "-539, टियांटियानले, ग्रैंड लोट्टो, मार्क सिक्स, 539 कार्ड, भूमिगत 539 गेमप्ले, 234 सितारे, पूरी कार, कार की सवारी, स्टेशन नंबर, कोई लाइसेंस नहीं, बहुविकल्पी नहीं, वैकल्पिक विकल्प, बीजिंग मोटरस्पोर्ट, लकी एयरशिप, जल्दी करें एयरशिप, बिंगो बिंगो, समय का रंग",
	"egame_description": "-स्लॉट गेम, स्लॉट गेम, स्लॉट मशीन टेबल, ऑनलाइन स्लॉट मशीन, स्लॉट मशीन, स्लॉट, गेम",
	"sport_description": "-एनबीए, सीबीए, केबीएल, एमएलबी यूईएफए चैंपियंस लीग, वर्ल्ड फुटबॉल कप, लकी लॉटरी, लकी लॉटरी बेटिंग, लकी लॉटरी हाउ टू बाय, लकी लॉटरी, प्ले लॉटरी, स्पोर्ट्स रोल, ताइवान लॉटरी, लॉटरी आयकॉन, युनकाई बेटिंग स्टेशन",
	"animal_description": " -animal",

	"about_title01": "हमारे बारे में",
	"agent_title01": "Agent",

	"check_title": "सॉफ्ट कैश शिक्षण",
	"check_list": [
		{
			"imgPath": "ios-clear-history",
			"title": "iPhone, iPad या iPod touch पर Safari में ब्राउज़र रिकॉर्ड और कुकी साफ़ करें।",
			"cont1": `समझें कि "सेट" में ब्राउज़र रिकॉर्ड, कुकी और कैश को कैसे हटाएं`,
			"cont2": "डिवाइस पर जानकारी साफ़ करने का तरीका",
			"cont3": "यदि आप ब्राउज़र और कुकी को साफ करना चाहते हैं, तो कृपया सेट>सफारी पर जाएं, और ब्राउज़र रिकॉर्ड और वेबसाइट की जानकारी साफ़ करें पर क्लिक करें। सफारी, कुकी और ब्राउज़र जानकारी में ब्राउज़र रिकॉर्ड हटाएं जो उस जानकारी को नहीं बदलेगा जो इसे स्वयं लिखें",
			"cont4": "यदि कुकी हटा रहे हैं लेकिन फिर भी ब्राउज़र रिकॉर्ड रखते हैं, तो कृपया सेट>सफारी>उन्नत>वेबसाइट जानकारी पर जाएं, फिर सभी वेबसाइट जानकारी हटाएं पर क्लिक करें।",
			"cont5": "कोई भी ब्राउज़र रिकॉर्ड या वेबसाइट जानकारी साफ़ नहीं कर सकती, सेट ग्रे हो जाएगा। यदि सामग्री और प्रोविसी सीमा के समय का उपयोग करते हुए स्क्रीन पर वेबसाइट सामग्री की सीमा पहले ही सेट कर दी गई है, तो सेट भी ग्रे हो जाएगा।"
		},
		{
			"imgPath": "android-clear-history",
			"title": "क्रोम पर ब्राउज़र रिकॉर्ड और कुकी साफ़ करें।",
			"cont1": "कुकी को हटाने के बाद, सिस्टम आपकी सभी वेबसाइट को लॉग आउट कर देगा, और आपके द्वारा सहेजी गई वरीयता सेट को हटाया जा सकता है।",
			"cont2": "डिवाइस पर जानकारी साफ़ करने का तरीका",
			"cont3": "Android सेल फ़ोन पर Chrome ऐप खोलें",
			"cont4": `वेबसाइट पर दाईं ओर "अधिक" आइकन> "सेट" पर क्लिक करें"`,
			"cont5": `"गोपनीयता">"ब्राउज़र जानकारी साफ़ करें" पर क्लिक करें`,
			"cont6": `समय सीमा चुनें, जैसे "एक घंटा पास करें" या "असीमित समय"`,
			"cont7": `"कुकी, मीडिया अधिकृत और वेबसाइट की जानकारी" की जाँच करें, और अन्य वस्तुओं की जाँच रद्द करें`,
			"cont8": `"स्वच्छ जानकारी"> "स्वच्छ" क्रम में क्लिक करें`,
		}
	],

	"disclaimer_title": "Privacy Policy",
	"disclaimer_list": [
		{
			"title": "",
			"cont1": "We respect our players' privacy and our first priority is to secure our players' data.",
			"cont2": "We will never reveal personal information of the players to third-parties unless we are requested by International Courts' ruling.",
			"cont3": "We reserve the right to provide the players' neccessary information for basic payment service providers, financial institutions, insurance companies via our website to complete payment requests.",
			"cont4": "All internal and external data will be stricly restricted and tightly monitored. our partners always notify the players of our promotions which they may concern about via email.",
			"cont5": "All internal and external data will be stricly restricted and tightly monitored. our partners always notify the players of our promotions which they may concern about via email.",
			"cont6": "In addtion, our purpose of privacy policy is not to disclose any players' information to third-parties.",
			"cont7": "",
			"cont8": "",
			"cont9": ""
		},
		{
			"title": "",
			"cont1": "",
			"cont2": "",
			"cont3": "",
			"danger": "Nobody is allowed to distribute, modify, copy, reissue, use we content or replicate our server without the express written consent of we."
		}
	],

	"add_app_desktop": "तालिका में एपीपी आईकॉन जोड़ें",
	"add_android_title": "Chrome विजेट को होम स्क्रीन पर जोड़ना",
	"add_android_step1": "ब्राउज़र पर ऊपरी दाएं आइकन पर क्लिक करें",
	"add_android_step2": `मुख्य पृष्ठ में जोड़ें" चुनें, फिर समाप्त करें`,
	"dont_show": "यह प्रश्न अब और नहीं दिखाई देगा",
	"add_app_ios_title": "वेबसाइट खोलने के लिए सफारी का उपयोग करें, फिर इस वेबसाइट को सेल फोन पेज पर रख सकते हैं, जिससे आप अपनी वेबसाइट को तेजी से कनेक्ट कर सकते हैं",
	"add_ios_step1": "कृपया ब्राउज़र के नीचे क्लिक करें",
	"add_ios_step2": `तालिका में "जोड़ें" पर क्लिक करें`,
	"add_ios_step3": '"जोड़ें" पर क्लिक करें, समाप्त करें',

	"btn": {
		"showhidden": "छुपा / देखना",
		"send_refresh": "रिफ्रेश",
		"send_new_pw": "नया पासवर्ड भेजें",
		"get_CAPTCHA": "सत्यापन कोड प्राप्त करें",
		"verify_btn": "सत्यापन भेजें",
		"error_feedback": "त्रुटि, कृपया फिर से दर्ज करें",
		"cancel_edit": "रद्द करें",
		"submit_confirm": "जमा करें",
		"submit_check": " जमा करें",
		"ok": "जमा करें",
		"submit": "जमा करें",
		"confirm": "जमा करें",
		"cancel": "रद्द करें",
		"reset": "रीसेट",
		"reset_again": "रीसेट",
		"login": "लॉग इन करें",
		"login_en": "",
		"signup": "रजिस्टर करें",
		"signup2": "रजिस्टर करें",
		"header_login_signup": "रजिस्टर करें और लॉगिन करें",
		"sign": "रजिस्टर करें",
		"agent_login": "लॉग इन करें ",
		"agent_sign": "रजिस्टर करें",
		"sign_en": "",
		"logout": "लॉग आउट",
		"user_login": "लॉग इन करें",
		"user_verify": "User Verify",
		"sign_up": "अभी रजिस्टर करें",
		"join_now": "रजिस्टर करें",
		"delete": "हटाएँ",
		"copy": "कॉपी",
		"close": "बंद करे",
		"close_all": "Close all",
		"search": "खोजें",
		"select_all": "संपूर्ण गेम्स",
		"enter_game": "खेलें",
		"play_game_now": "Play",
		"free_try_play": "Try now",
		"try_play": "Try now",
		"CAPTCHA": "सत्यापन कोड",
		"turnpoint": "जमा करें"
	},

	"withdrawal": {
		"withdrawal": "निकासी",
		"treasure_trial": "खेप परीक्षण गणना",
		"treasure_note": "खेप पर सावधानियां",
		"wallet_treasure": "निकासी",
		"treasure_records": "Consign REC",
		"no_sale_record": "कोई सूचना नहीं",
		"treasure_bank_name": "इससे स्थानांतरित करें",
		"treasure_amount": "रकम",
		"treasure_bank_card": "खेप के बैंक कार्ड",
		"choose_bank_feedback": "कृपया खेप के बैंक कार्ड चुनें",
		"treasure_point": "Transfer Amount",
		"treasure_point_limit": "खेप बिंदुओं के बीच की जरूरत है",
		"plz_treasure_point": "कृपया खेप अंक दर्ज करें",
		"treasure_precautions": "खेप सूचना",
		"plz_treasure_agree": `कृपया "कंसाइनमेंट नोटिस" से सहमत हों`,
		"note_1": "तीन बार निकासी की सीमा, सप्ताह में तीन बार मुफ्त हैंडलिंग शुल्क, निकासी राशि का 1% और चौथी बार हैंडलिंग शुल्क के रूप में 50 डॉलर। (हर सोमवार 00:00 ~ रविवार 23:59)",
		"note_2": "यदि इस मनोरंजन का उपयोग मनी लॉन्ड्रिंग या धोखाधड़ी के लिए किया जाता है, तो इस मनोरंजन को बिना किसी सूचना के सदस्य सेवा को हमेशा के लिए बंद करने का अधिकार है।",
		"note_3": "यदि मॉनिटर अपार्टमेंट किसी व्यक्ति या समूह को लाभ प्राप्त करने के लिए बेईमान तरीकों का उपयोग करते हुए पाता है, वही खाता / परिवार / फोन नंबर / जमा स्थान / बैंक खाता / एसएमई या परिचित आईपी / इंटरनेट ..., कंपनी को रखने का अधिकार है, अन्यायपूर्ण मुनाफे को रद्द या रीसायकल करें।",
		"note_4": "खाता अपार्टमेंट भेजने का समय लगभग १५-३० मिनट है",
	},

	"desopit": {
		"desopit": "जमा।",
		"desopit_tycc": "जमा।",
		"fields_order_id": "लेन-देन आईडी",
		"fields_type": "भुगतान के प्रकार",
		"desopit_amount": "जमा राशि",
		"hello_desopit_info": "नमस्ते, आपकी जमा जानकारी निम्नलिखित है",
		"desopit_qrcode": "QR कोड भुगतान",
		"desopit_mode": "जमा प्रकार ",
		"desopit_account": "जमा खाता",
		"desopit_code": "जमा कोड",
		"desopit_now": "जमा।",
		"desopit_tyc": "जमा।",
		"choose_paybank_select": "--- कृपया टॉप अप करने के लिए बैंक डेबिट कार्ड चुनें ---",
		"choose_paybank_feedback": "कृपया टॉप अप करने के लिए बैंक डेबिट कार्ड चुनें",
		"choose_paystore": "Please select",
		"desopit_bank_card": "टॉप अप बैंक कार्ड",
		"point_desopit": "जमा।",
		"desopit_point": "जमा रकम",
		"plz_enter_point": "कृपया जमा अंक दर्ज करें",
		"desopit_limit": "जमा अंक के बीच होना चाहिए",
		"desopit_point_info": "जमा बिंदुओं की जानकारी",
		"plz_deposit_receipt": 'कृपया "जमा रसीद" अपलोड करें',
		"upload_deposit_receipt": "भुगतान अपलोड करें",
		"deposit_receipt": "जमा रसीद",
		"deposit_records": "जमा रिकॉर्ड",
		"no_deposit_records": "कोई सूचना नहीं",
		"deposit_detail": "विवरण",
		"deposit_others": "अन्य",
		"crypto_address": "Crypto Address",
		"wallet_address": "Wallet Address",
		"plz_choose_crypto_address_feedback": "Please select",
		"plz_choose_crypto_address_select": "--- Please select ---",
		"pay_virtual_currency": "Deposit Crypto",
		"plz_enter_crypto": "Please enter",
		"cancel": "cancel desopit",
		"desopit_amount_range": "desopit amount range"
	},

	"discount": {
		"activity": "प्रमोशन",
		"choose_discount_select": "--- कृपया छूट का चयन करें ---",
		"choose_discount_feedback": "कृपया छूट का चयन करें",
		"records": "प्रमोशन",
		"apply": "छूट आवेदन",
		"btnYes": "मेरी रूचि है",
		"time": "प्रमोशन की अवधि।",
		"object": "वस्तु को बढ़ावा देना",
		"content": "सामग्री का प्रचार करें",
		"rule": "निबंधन और शर्तें",
		"act_name": "प्रमोशन नाम ",
		"amount": "बोनस राशि ",
		"mode": "छूट प्रकार",
		"nothing": "कोई जानकारी नहीं मिली!",
		"search": "खोज",
		"type": "छूट प्रकार",
		"name": "छूट नाम",
		"description": "छूट विवरण",
		"type_1": "स्थानीय जमा छूट ",
		"type_2": "तृतीय-पक्ष भुगतान छूट",
		"type_4": "स्वयं सहायता आवेदन छूट",
		"application": "छूट आवेदन",
		"applied": "छूट लागू",
		"first": "पहली आरक्षित सीमा",
		"full": "आवेदकों की संख्या भरी हुई है",
		"doing_d": "प्रचार समाप्त हो गया है",
		"doing_o": "",
		"doing_i": "",
		"doing_n": "",
		"doing_g": ""
	},

	"wallet": {
		"name": "Name",
		"plz_choose_out": "कृपया चुनें।",
		"plz_choose_in": "कृपया चुनें।",
		"back_wallet": "एक-क्लिक रिकवरी",
		"digital_wallet": "बटुआ",
		"wallet_balance": "वॉलेट बैलेंस",
		"wallet_address": "Wallet Address",
		"plz_input_wallet_address": "Please enter wallet address",
		"deposit_wallet_address": "Wallet Address",
		"tranfer_wallet_address": "Wallet Address",
		"enter_ob_tip": "{game} गेम में प्रवेश करते हुए, अंक 5:1 पर प्रदर्शित होते हैं। उदाहरण के लिए, यदि मुख्य वॉलेट में 1000 अंक हैं, तो {game} में प्रवेश करने पर 200 प्रदर्शित होंगे, और गेम लॉबी में वापस स्थानांतरण भी इस अनुपात के अनुसार किया जाएगा। उदाहरण के लिए, {game} बैलेंस 200 है, और मुख्य वॉलेट में वापस ट्रांसफर 1000 अंक है।",
		"enter_exch_tip_10": "{game} गेम में प्रवेश करते हुए, अंक 10:1 पर प्रदर्शित होते हैं। उदाहरण के लिए, यदि मुख्य वॉलेट में 1000 अंक हैं, तो {game} में प्रवेश करने पर 100 प्रदर्शित होंगे, और गेम लॉबी में वापस स्थानांतरण भी इस अनुपात के अनुसार किया जाएगा। उदाहरण के लिए, {game} बैलेंस 100 है, और मुख्य वॉलेट में वापस ट्रांसफर 1000 अंक है।",
		"mobile_wallet_info": "Mobile payment information（Please enter the information to be bound, once bound, all withdrawals will be automatically remitted to this mobile wallet）",
		"choose_transfer_virtual_address_select": "कृपया चुनें।",
	},

	"bank": {
		"table_code": "जमा कोड",
		"bank_name": "बैंक का नाम",
		"bank_user": "खातेदार का नाम",
		"bank_number": "बैंक खाता नंबर",
		"bank_book": "बैंक की किताब",
		"choose_transferbank_select": "--- कृपया ट्रांसफर बैंक चुनें ---",
		"choose_transferbank_feedback": "कृपया ट्रांसफर बैंक चुनें",
		"transfer_bank": "बैंक खाता नंबर",
		"transfer_bank_info": "बैंक जानकारी बदलने के लिए, कृपया ग्राहक सेवा से संपर्क करें। खाता नाम रजिस्टर नाम और आईडी नाम के समान होना चाहिए। खेप के लिए नाम प्रमाण पत्र की आवश्यकता है, यदि समान नहीं है, तो परेषित नहीं कर सकते। (नाम बदल नहीं सकते)",
		"bank_transfer_info": "खाता नाम रजिस्टर नाम / आईडी नाम के समान होना चाहिए, कंसाइनमेंट को नाम प्रमाण पत्र की आवश्यकता है, यदि यह समान नहीं है तो कंसाइन नहीं कर सकता (नाम अपरिवर्तनीय हैं), यदि आप बदलना चाहते हैं तो ग्राहक सेवा से संपर्क करें।",
		"only_bind_bank": "केवल बंधे हुए बैंक द्वारा हस्तांतरण भुगतान स्वीकार करें",
		"bind_bank": "Tied with bank",
		"bankcard_info": "बैंक खाता",
		"bank_branch": "ब्रांच",
		"bank_branch_open": "ब्रांच",
		"choose_bankcard_select": "--- कृपया चुनें। ---",
		"bind_bank_account": "बैंक खाते से बंधे",
		"bank_card_info": "बैंक कार्ड की जानकारी (कृपया बंधी हुई जानकारी दर्ज करें, जैसे ही आप बंधे होंगे, सभी निकासी इस बैंक कार्ड में स्थानांतरित हो जाएगी)",
		"plz_accurately_info": "जिस बैंक कार्ड से बंधा होता है उसका उपयोग खेप के लिए किया जाता है। अपनी खेप के वितरण से निपटने के लिए, कृपया सुनिश्चित करें कि आपने खाते की सही जानकारी लिखी है",
		"bankuser_cannot_over": "बैंक खाते का नाम 50 शब्दों से अधिक नहीं हो सकता",
		"bankuser_cannot_required": "बैंक का नाम खाली या गलत प्रारूप नहीं हो सकता",
		"plz_select_bank": "कृपया चुनें।",
		"bank_number_required": "बैंक का नाम खाली या गलत प्रारूप नहीं हो सकता",
		"bank_branch_required": "शाखा का नाम खाली या गलत प्रारूप नहीं हो सकता",
		"plz_transfer_bank": "ट्रांसफर करने के लिए कृपया असाइन किए गए बैंक खाते में जाएं",
		"transfer_oldbank_not_check": "यदि पुराने बैंक खाते में स्थानान्तरण किया जाता है, तो यह प्राप्त नहीं हो सकता",
		"not_responsible": "इसकी कोई जिम्मेदारी नहीं",
		"the_website": "यह वेबसाइट",
		"not_atm_card_cash": "बिना कार्ड के एटीएम कैश जमा करने का तरीका स्वीकार नहीं कर सकते",
		"plz_transfer_remittance": "अपना समय विलम्ब न करने के लिए कृपया बैंक खाते से स्थानांतरण का उपयोग करें",
	},

	"transfer": {
		"third_atm": "ATM स्थानांतरण , एटीएम स्थानांतरण",
		"user": "खाता",
		"account": "व्यक्तिगत खाता।",
		"submit_after": "ट्रांसफर के बाद सबमिट करें",
		"linepay_tip": "※कृपया क्यूआर कोड का स्क्रीनशॉट लें और फिर लाइन पर ट्रांसफर करें",
		"jkopay_tip": "※कृपया QR कोड का स्क्रीनशॉट लें और फिर JKO Pay को स्कैन करें और ट्रांसफर करें",
		"qrcode_tip": "※कृपया क्यूआर कोड का स्क्रीनशॉट लें और फिर ट्रांसफर करने के लिए एपीपी को स्कैन करें",
		"already": "पहले से ही मनी ट्रांसफर",
		"been_transferred": "पहले से ही मनी ट्रांसफर",
		"virtual_address": "Transfer to",
		"only_virtual_address": "Only accept the transfter payment by Crypto Address",
		"point_records": "स्थानांतरण अंक रिकॉर्ड",
		"no_turning_point": "कोई सूचना नहीं",
		"turnaround_time": "स्थानांतरण अंक समय",
		"iwant": "मैं जाना चाहता हूं",
		"goto": "अंक स्थानांतरित करें",
		"enterpoint": "कृपया स्विच पॉइंट दर्ज करें",
		"onlyInteger": "कृपया स्विच पॉइंट दर्ज करें, केवल पूर्णांक",
		"turn_point": "कोटा ट्रांसफर",
		"points_records": "कोटा ट्रांसफर",
		"turning_point": "Point giveaway",
		"turning_coin_record": "बिंदु परिवर्तन इतिहास",
		"turning_coin": "उपहार सोने के सिक्के",
		"have_coin": "सोने के सिक्के लो",
		"turning_reduce_fees": "ट्रांसफर शुल्क काटा जाएगा",
		"maximum_coin": "एकल हस्तांतरण की अधिकतम राशि",
		"minimum_coin": "एकल हस्तांतरण की न्यूनतम राशि",
		"donate_point_member": "प्वाइंट सस्ता",
		"donate_point_account": "सदस्य खाता",
		"donate_points": "Point giveaway Amount",
		"donate_points_limit": "खाली या गलत प्रारूप नहीं हो सकता",
		"created_at_transfer": "Point giveaway Time",
		"transfer_mode": "Point giveaway type",
	},

	"stroe": {
		"store_barcode": "सुविधाजनक बारकोड",
		"store_ibon": "7-11",
		"store_familys": "Familys",
		"store_ok": "OK mart",
		"store_hilife": "Hi-Life",
		"third_store": "सुविधा स्टोर में भुगतान करें",
		"plz_save_store_receipt": "सुविधा स्टोर में भुगतान के बाद ट्रांसफर रसीद की तस्वीरें लें, बिना रसीद के अंक नहीं देंगे",
		"store_name": "सुविधा स्टोर का नाम",
		"store_code": "सुविधा स्टोर कोड",
	},

	"category_0": "HOT",
	"category_999": "Fish",

	"customer": {
		"online_hi": "हाय ~ क्या मैं आपकी मदद कर सकता हूँ?",
		"h24": "24H",
		"h24_service": "आपके लिए 24 घंटे ऑनलाइन परामर्श",
		"service": "ग्राहक सेवा",
		"service_contact": "ग्राहक सेवा",
		"service_line": "LINE",
		"line_service": "LINE",
		"contact_us": "हमसे संपर्क करें",
		"uh_oh": "UH-OH! , उह ओह!",
		"plz_contact_us": "कृपया ग्राहक सेवाओं से संपर्क करें",
		"online_howru": "कृपया ग्राहक सेवा लाइन जोड़ें",
		"service_tw": "ऑनलाइन ग्राहक सेवा",
		"service_en": "Online Service",
		"contact_service_center": "यदि कोई समस्या है, तो ग्राहक सेवा से संपर्क करने के लिए आपका स्वागत है",
		"service_profile_info": "जो जानकारी पहले ही प्रदान कर दी गई है, उसे बदला नहीं जा सकता. कृपया ग्राहक सेवा से संपर्क करके जानकारी बदलें।",
		"service_contact_upload": "इस वेबसाइट का रखरखाव कम है, किसी भी असुविधा के लिए खेद है, यदि कोई समस्या है, तो कृपया ग्राहक सेवा से संपर्क करें",
		"telegram": "Telegram",
		"whatsapp": "WhatsApp",
		"facebook": "Facebook",
		"instagram": "Instagram",
		"twitter": "Twitter",
		"maintenance_title1": "मेंटेनेन्स के तहत",
		"maintenance_title2": "",
		"maintenance_title3": "",
		"maintenance_title4": "",
		"maintenance_title5": "",
		"maintenance_title6": "",
		"maintenance_title7": "",
	},

	"copy": {
		"friend_copy": "जल्दी करें! शामिल होने के लिए अपने रिश्तेदारों और दोस्तों को आमंत्रित करें!",
		"friend_promotion_url": "कॉपी कोड ",
		"copy_Succe": "सफल हुए",
		"copy_error": "विफलता",
	},

	"upload": {
		"receipt": "भुगतान अपलोड करें",
		"not_receipt": "कोई अपलोड नुस्खा नहीं",
		"tip_prompt_sample": "तस्वीर का नमूना अपलोड करें",
		"tip_digit_sample": "तस्वीर का नमूना अपलोड करें",
		"identity_title": "आईडी कार्ड अपलोड उदाहरण",
		"identity_main_content": "जब आप तस्वीरें लेना या आईडी कार्ड अपलोड करना चुनते हैं (यदि आवश्यक हो तो अपलोड न करें, जब कंपनी को इसकी आवश्यकता होगी, तो कर्मचारी इसके लिए पूछेंगे, उदाहरण के लिए: वही उपनाम, वही पहला नाम, कृपया ध्यान दें)",
		"tip_digit_content": "जब आप आईडी/बैंक बुक अपलोड करने के लिए तस्वीर लेना या स्कैन करना चुनते हैं, तो कृपया निम्नलिखित बातों पर ध्यान दें।",
		"tip_entity_sample": "तस्वीर का नमूना अपलोड करें",
		"tip_entity_content": "जब आप आईडी/बैंक बुक अपलोड करने के लिए तस्वीर लेना या स्कैन करना चुनते हैं, तो कृपया निम्नलिखित बातों पर ध्यान दें।",
		"tip_prompt_content": "जब आप आईडी/बैंक बुक अपलोड करने के लिए तस्वीर लेना या स्कैन करना चुनते हैं, तो कृपया निम्नलिखित बातों पर ध्यान दें।",
		"upload_exp": "नमूना अपलोड करें",
		"plz_upload_pic": "Please upload photo",
		"only_img_upload": "केवल तस्वीरें अपलोड करें",
		"not_pic": "गैर चित्र",
		"only_three_pic": "तीन तस्वीरें सीमित करें",
		"plz_upload_profile": `कृपया "व्यक्तिगत नाम कार्ड" अपलोड करें`,
		"plz_jkopay_profile": "कृपया JKO व्यक्तिगत नाम कार्ड अपलोड करें",
		"upload_limit_over": "फ़ाइल अपलोड करने की सीमा से अधिक",
		"plz_number_profile": "कृपया आईडी कार्ड दर्ज करें",
		"plz_upload_profile_front": "कृपया आईडी कार्ड अपलोड करें (सामने की ओर)",
		"plz_upload_profile_reverse": "कृपया आईडी कार्ड अपलोड करें (पीछे की ओर)",
		"plz_upload_bank_passbook": `कृपया मूल "बैंक बुक" अपलोड करें`,
		"plz_upload_id_card": 'कृपया मूल "आईडी कार्ड" अपलोड करें',
	},

	"pw": {
		"plz_enter_safe_code": "सुरक्षित पासवर्ड दर्ज करें",
		"safe_code": "Secure password",
		"forgot": "पासवर्ड भूल गए",
		"forgot_mark": "पासवर्ड भूल गए ?",
		"ButSendCode_newpw": "नया पासवर्ड भेजें",
		"ButSendCode_reset": "एक सेकंड में फिर से भेजें",
		"tip_idpw_not_same": "प्लेयर आईडी से अलग होना चाहिए ",
		"password": "पासवर्ड",
		"confirmation": "पासवर्ड की पुष्टि कीजिये",
		"safe_pw_feedback": "खाली या गलत प्रारूप नहीं हो सकता",
		"user_pw": "पासवर्ड",
		"user_chgpw": "पासवर्ड बदलें",
		"chgsafepw": "सुरक्षित पासवर्ड बदलें",
		"old_pw": "वर्तमान पासवर्ड",
		"old_pw_feedback": "वर्ण की लंबाई मान्य सीमा (6- 20) में होनी चाहिए ",
		"new_pw": "नया पासवर्ड",
		"new_pw_feedback": "वर्ण की लंबाई मान्य सीमा (6- 20) में होनी चाहिए ",
		"new_pw_again": "नए पासवर्ड की पुष्टि करें",
		"new_pw_again_feedback": "कृपया नए पासवर्ड की फिर से पुष्टि करें।",
		"set_safe_pw": "सुरक्षित पासवर्ड बदलें",
		"edit_safe_pw": "सुरक्षित पासवर्ड बदलें",
		"old_safe_pw": "वर्तमान पासवर्ड",
		"old_safe_pw_feedback": "खाली या गलत प्रारूप नहीं हो सकता",
		"new_safe_pw": "नया पासवर्ड",
		"new_safe_pw_feedback": "खाली या गलत प्रारूप नहीं हो सकता"
	},

	"vip": {
		"vip_freeback":"VIP",
    "vip_info": "VIP info.",
    "vip_title": "VIP",
	},

	"virtual": {
		"buycoin_view": "View for Buying Crypto",
		"channel": "Protocol",
		"name": "Virtual Name",
		"bitcoin": "आभासी मुद्रा",
		"account": "आभासी खाते",
		"currency": "Cryptocurrency",
		"tied_crypto": "Tied with Crypto",
		"currency_title": "Crypto Address",
		"virtual_address": "खेप का आभासी मुद्रा पता",
		"choose_address_select": "--- कृपया एक आभासी मुद्रा पता चुनें ---",
		"choose_address_feedback": "कृपया खेप के लिए आभासी मुद्रा पता चुनें",
		"currency_limit": "The points should between",
		"currency_coin_limit": "The virtual currency should between",
		"treasure_currency": "खेप आभासी मुद्रा",
		"treasure_currency_limit": "खेप आभासी मुद्रा के बीच होना चाहिए",
		"plz_treasure_currency": "कृपया माल की आभासी मुद्रा दर्ज करें",
		"plz_input_name": "Please select",
		"plz_select_channel": "Please select",
		"plz_upload_profile": "Please upload 【wallet address】",
		"wallet_address": "Wallet Address",
		"exchangeRate": "FX rate",
		"exchangeRatePoint": "Change Point",
		"pay": "क्रिप्टोक्यूरेंसी भुगतान",
	},

	"pay": {
		"payment": "डिजिटल भुगतान के साथ बंधे",
		"proceed": "Proceed",
		"qrcode": "क्यूआर कोड",
		"tide_digital": "डिजिटल भुगतान के साथ बंधे",
		"linepay_account": "iPASS Money account",
		"jkopay_account": "जेकेओ पे अकाउंट",
		"mobile_pay_info": "मोबाइल भुगतान जानकारी (यदि आप बदलना चाहते हैं तो कृपया ग्राहक सेवा से संपर्क करें)",
		"mobile_wallet_info": "मोबाइल भुगतान जानकारी (वह जानकारी दर्ज करें जिसे आप टाई करना चाहते हैं, एक बार जब आप थक जाएंगे, तो सभी निकासी इस इलेक्ट्रॉनिक पर्स में स्थानांतरित हो जाएगी)",
		"linepay": "iPASS Money",
		"mobile_pay_required": "मोबाइल भुगतान खाली या गलत प्रारूप में नहीं हो सकता",
		"third": "तृतीय-पक्ष भुगतान छूट",
		"icash_pay_account": "icashPay account",
		"px_pay_account": "PxPay account",
		"plz_linepay_profile": "Please upload iPASS Money account",
		"plz_upload_linepay": `Please upload "iPASS Money account" screenshot`,
		"plz_upload_icashpay": `Please upload "icash account" screenshot`,
		"plz_upload_pxpay": `Please upload "PxPay account" screenshot`,
	},

	"user": {
		"join": "साइन अप करें",
		"join_agent": "साइन अप करें",
		"agent": "एजेंट आईडी",
		"agent_account": "Agent ID",
		"id": "प्लेयर आईडी",
		"account": "आईडी",
		"password": "पासवर्ड",
		"password_confirmation": "पासवर्ड की पुष्टि कीजिये",
		"email": "ईमेल",
		"name": "वास्तविक नाम",
		"w_code": "सुरक्षा कोड",
		"birthday": "जन्म तिथि",
		"nickname": "उपनाम",
		"realname": "वास्तविक नाम",
		"user_email": "ईमेल",
		"coin": "वॉलेट बैलेंस",
		"mobile": "संपर्क नंबर",
		"line_id": "LINE ID",
		"telegram_id": "Telegram ID",
		"password_notice": "6 ~ 20 अल्फ़ान्यूमेरिक होना चाहिए",
		"password_confirmation_notice": "अपना पासवर्ड पुनः दर्ज करें",
		"name_notice": "कृपया अपना वास्तविक नाम दर्ज करें, पंजीकरण के बाद बदल नहीं सकते",
		"line_id_notice":"",
		"user_total_bet": "कुल सट्टा",
		"enter_error_feedback": "गलत दर्ज",
		"user_data": "सामान्य जानकारी",
		"profile": "Member Center",
		"user_account": "Player ID",
		"user_id": "प्लेयर आईडी",
		"target_account": "प्रतिपक्ष",
		"phone_number": "संपर्क नंबर",
		"required": "खाली या प्रारूप त्रुटि",
		"captcha": "सत्यापन कोड",
		"captcha_required": "खाली सत्यापन कोड, कृपया दर्ज करें",
		"agree": "मेरी आयु कम से कम 18 वर्ष है और मैंने सभी नियमों और शर्तों को पढ़ और स्वीकार कर लिया हैl",
		"terms": "नियम और शर्तें",
		"submit": "जमा करें",
		"verify_btn": "सत्यापन भेजें",
		"account_notice": "6 ~ {max} अल्फ़ान्यूमेरिक होना चाहिए",
		"name_notice": "कृपया अपना वास्तविक नाम दर्ज करें, पंजीकरण के बाद बदल नहीं सकते",
		"id_group612": "पासवर्ड 6 ~ 12 अल्फ़ान्यूमेरिक होना चाहिए",
		"id_group620": "English and number, at least 6 ~ 2 (notice the big or small letter)",
		"rememberme": "Remember",
		"tip_idpw_not_same": "प्लेयर आईडी से अलग होना चाहिए",
		"tip_phone_captcha_first": "कृपया पहले सेलफोन सत्यापन कोड भेजें",
		"tip_plz_agree": "कृपया संबंधित नियमों और सेवा शर्तों से सहमत हों",
		"tip_id_ok": "The account is checked successfully.",
		"tip_phone_captcha_send": "मोबाइल प्रमाणीकरण कोड आपको फ़ोन नंबर पर भेज दिया गया है ",
		"ButSendMobileCode_second_reset": "एक सेकंड में फिर से भेजें",
		"ButSendMobileCode_captcha_send": "सत्यापन भेजें",
		"join_description": "",
		"login_description": "",
		"forgot_description": "",
		"whats_app": "WhatsApp",
		"registered_mobile": "Please enter your registered mobile number",
		"line_login": "Line Login",
		"area_code": "Area code"
	},

	"placeholder": {
		"agent": "Please enter agent code",
		"account": "Please enter player ID",
		"captcha": "Please enter validation code",
		"email": "Please enter email",
		"line_id": "Please enter Line ID",
		"mobile": "Please enter mobile",
		"name": "Please enter name",
		"nickname": "Please enter nickname",
		"password": "Please enter password",
		"password_confirmation": "Please enter password confirmation",
		"telegram_id": "Please enter Telegram ID",
		"whats_app": "Please enter Whats App",
	},

	"dialogRule_title": "नियम और शर्तें",
	"dialogRule_list": [
		{
			"title": "नियम और शर्तें",
			"cont1": "सदस्य होने को समझने और पंजीकरण करने के बाद, आप हमारे द्वारा प्रदान की जाने वाली सभी सेवाओं का उपयोग कर सकते हैं"
		},
		{
			"title": "सदस्य नियमों और कानून का पालन करें",
			"cont1": "जब सेवाओं का उपयोग करने वाले सदस्यों का मतलब है कि आप न केवल सेवा शर्तों से सहमत हैं, बल्कि हमारी कंपनी (सभी खेल नियमों, बॉल गेम सट्टेबाजी के नियमों, नोटिस और सावधानियों सहित ...), और कानून से संबंधित सीमा, सभी उत्पादों से भी सहमत हैं। कंपनी भी ग्राहक सेवा, सॉफ्टवेयर सिस्टम, इंटरनेट संरचना, ect संबंधित व्यावसायिक सामान फिलीपींस मनोरंजन और सट्टेबाजी संस्थान द्वारा सत्यापित हैं।"
		},
		{
			"title": "सेवा परिचय",
			"cont1": "कंपनी का अर्थ सुरक्षा, तत्काल संयोजक, बनाना ह निष्पक्ष और न्यायपूर्ण, पेशेवर दौड़ना अच्छा मनोरंजन सेवा मंच। खिलाड़ियों को असीमित स्थान पर जोर देते हुए, 24 घंटे किसी भी समय एक न्यायपूर्ण खेल में शामिल हों, दुनिया भर के खिलाड़ियों के साथ खेलने का भी आनंद ले सकते हैं। खिलाड़ी पहले हैं का पालन करने के लिए हमारा दृष्टिकोण है, नवाचार और खिलाड़ियों की जरूरत है हमारी प्रबंधन अवधारणा है जिस पर हम गर्व करते हैं, एक नया मनोरंजन मूल्य बनाने और सार्वभौमिक होने की उम्मीद करते हुए, मनोरंजन वेबसाइट पर एक नेता बनें।",
			"cont2": "कंपनी ऑनलाइन भुगतान का मंच प्रदान करती है, आपके द्वारा खरीदे गए अंक कंपनी के मंच पर सभी सेवाओं का आनंद ले सकते हैं। यदि आप अन्यायपूर्ण तरीके से अंक खरीदते हैं, तो कंपनी आपकी सदस्यता और प्लेटफ़ॉर्म सेवाओं पर अधिकार कभी भी रोक सकती है।"
		},
		{
			"title": "वास्तविक लॉग इन ड्यूटी",
			"cont1": "कंपनी सेवाओं के आधार पर, आप सदस्यता के लिए पंजीकरण करते समय दिखाई देने वाली वस्तुओं से सहमत होते हैं, वास्तविक, सटीक और पूर्ण व्यक्तिगत जानकारी के साथ लॉग इन करते हैं। यदि आपकी जानकारी में परिवर्तन होता है, तो कृपया सही, वास्तविक और पूर्ण जानकारी बनाए रखने के लिए इसे तुरंत बदलें।",
			"cont2": "यदि आप नकली सूचनाओं के साथ लॉग इन करते हैं या अन्य लोगों के नामों का उपयोग करते हैं जो अन्य लोगों के अधिकारों का उल्लंघन करते हैं, तो आपको कानूनी दायित्व के साथ खड़ा होना चाहिए और सहमत होना चाहिए कि क्या आपकी जानकारी बदल जाती है लेकिन आपने इसे तुरंत नहीं बदला है जिससे सूचना वास्तविक नहीं है, कंपनी के पास अधिकार हैं अपनी सदस्यता और कंपनी की सभी सेवाओं को रोकने के लिए।"
		},
		{
			"title": "सेवा बंद करो या बदलो",
			"cont1": "• सदस्य फर्जी जानकारी में लॉग इन करें",
			"cont2": "• हमारी सेवा को लागू करने के लिए अन्य लोगों के नाम का प्रयोग करें",
			"cont3": "• खेल न्याय नियम का उल्लंघन करें",
			"cont4": "• खेलों में शामिल होने पर, सिस्टम त्रुटियों को ढूंढें या उद्देश्य पर परिणामों को प्रभावित करने के लिए गेम की कमजोरी का उपयोग करें।",
			"cont5": "• इस सेवा शर्तों का उल्लंघन करें",
			"cont6": "• कंपनी अन्यायपूर्ण तरीके से, डबल बेटिंग, बिना जोखिम वाले तरीके की अनुमति नहीं देती है। यदि उन खिलाड़ियों का पता लगाया जाए जो उपरोक्त तरीकों से खेलते हैं, तो कंपनी के पास सदस्य अधिकारों को रोकने का अधिकार है। यदि कोई विवाद होता है, तो निर्णय लेने का अंतिम अधिकार कंपनी के पास होता है।",
			"cont7": "• इससे कोई फर्क नहीं पड़ता कि सेवा को रोकना या बदलना या सदस्य खातों को रोकना जिससे नुकसान, असुविधाजनक, परेशानी हो सकती है, इस सेवा का किसी भी सदस्य या तीसरे व्यक्ति के लिए कोई कर्तव्य नहीं होगा।"
		},
		{
			"title": "सेवा बंद या बाधित",
			"cont1": "यह कंपनी किसी भी निम्न स्थितियों से इस सेवा या सभी सेवा को रोक या बाधित कर सकती है, और खिलाड़ियों के लिए कोई मुआवजे की जिम्मेदारी नहीं है।",
			"cont2": "• जब संबंधित सिस्टम चल रहा हो, बदल रहा हो या बनाए रख रहा हो।",
			"cont3": "• सेवा बंद या बाधित होने के लिए कंपनी को दोष नहीं दे सकते।",
			"cont4": "• उन कारणों से जो सेवा को रोकने या बाधित करने के लिए नियंत्रित नहीं कर सकते हैं।",
			"cont5": "यदि कंपनी संबंधित सिस्टम को चलती है, बदलती है, या बनाए रखती है, जिसे या सभी सेवा के कुछ हिस्सों को रोकना या बाधित करना है, तो कंपनी ऐसा होने से पहले वेबसाइट पर ईमेल या नोटिस भेज देगी।",
			"cont6": "कंपनी अन्यायपूर्ण तरीके से, डबल बेटिंग, बिना जोखिम वाले तरीके की अनुमति नहीं देती है। यदि उन खिलाड़ियों का पता लगाया जाए जो उपरोक्त तरीकों से खेलते हैं, तो कंपनी के पास सदस्य अधिकारों को रोकने का अधिकार है। यदि कोई विवाद होता है, तो निर्णय लेने का अंतिम अधिकार कंपनी के पास होता है।",
			"cont7": "सेवा बंद या रुकावट के बारे में जो आपकी असुविधा, सूचना हानि या आर्थिक या समय पर नुकसान का कारण बन सकती है। आपको अपने अधिकारों की रक्षा के लिए कुछ सुरक्षा लागू करनी चाहिए।"
		},
		{
			"title": "कर्तव्य पालन।",
			"cont1": "सदस्यों का कर्तव्य है कि वे वेबसाइट पर खाते और पासवर्ड रखें, और खाते और पासवर्ड के सेट में लॉग इन करने के बाद सभी कार्यों की जिम्मेदारी लें।",
			"cont2": "सदस्य अधिकारों की रक्षा के लिए, अन्य लोगों के उपयोग के लिए लीक, देना, उधार देना या हस्तांतरण न करें।"
		},
		{
			"title": "खेल और खेल के सभी नियमों से सहमत हों।",
			"cont1": "सट्टेबाजी के खेल पर विवाद करने वाले उपयोगकर्ताओं से बचने के लिए, किसी भी खेल पर सभी नियमों के लिए विस्तृत शर्तें हैं, कृपया सेवा और नियमों को स्पष्ट रूप से पढ़ें।",
			"cont2": "जब सदस्य इस सेवा का उपयोग करना शुरू करते हैं, तो इसका मतलब है कि आपने पहले ही सभी नियमों को स्वीकार कर लिया है।"
		},
		{
			"title": "जोड़ने और बदलने के सदस्य नियम।",
			"cont1": "यदि सेवा की शर्तें जोड़ या बदल रही हैं, तो आप सहमत हैं कि यह वेबसाइट पर नोटिस करने पर मान्य है, वेबसाइट व्यक्तिगत रूप से नोटिस नहीं करेगी।",
			"cont2": "यदि आप नोटिस के बाद सेवा का उपयोग करते हैं तो इसका मतलब है कि आप इस शर्त से सहमत हैं जो बदली गई है।"
		},
		{
			"title": "सदस्य नीति",
			"cont1": "हम सदस्य गोपनीयता की रक्षा करने और सबसे सुरक्षित गेम प्लेटफॉर्म प्रदान करने के लिए समर्पित हैं, वेबसाइट पर जो जानकारी हम एकत्र करते हैं वह आपको सबसे बड़ी सेवा प्रदान करेगी, हम आपकी जानकारी को तीसरे व्यक्ति के लिए बिक्री या किराए पर नहीं देंगे।",
			"cont2": "ग्राहक जो जानकारी प्रदान करते हैं, वे सभी SSL128 द्वारा एन्क्रिप्ट की जाती हैं और सुरक्षित, गैर-सार्वजनिक प्रणाली में सहेजी जाती हैं। जिन भागीदारों के पास सदस्य की व्यक्तिगत जानकारी से निपटने का अवसर है, उन्हें हमारे प्रोविसी नियमों का पालन करना होगा।"
		},
		{
			"title": "नियम और शर्तें",
			"cont1": "अधिकांश क्षेत्र या देश में ऑनलाइन सट्टेबाजी पर स्पष्ट वैधता नहीं है, यहां तक कि कुछ क्षेत्रों या देशों का कहना है कि ऑनलाइन सट्टेबाजी अवैध है। हमारी सेवा का उपयोग करने के लिए उस देश में से किसी को भी आमंत्रित करने की हमारी कोई इच्छा नहीं है।",
			"cont2": "उपयोगकर्ताओं को यह सुनिश्चित करना होगा कि आपके क्षेत्र या देश में ऑनलाइन सट्टेबाजी के लिए कानूनी है या नहीं, और सभी जिम्मेदारी लें। सदस्यों को स्थानीय कानून का पालन करना चाहिए, यदि उल्लंघन होता है, तो कंपनी का कोई कर्तव्य नहीं है।"
		}
	],

	"event":{
		"activity_description":"गतिविधि विवरण",
		"activity_methods":"गतिविधि विधि",
		"remain_times":"शेष समय",
		"login":"कृपया पहले सदस्य के रूप में लॉग इन करें",
		"event_period_not_started_or_hasended":"वर्तमान पदोन्नति समाप्त हो गई है",
		"no_corresponding_flag":"असंबंधित सदस्य ध्वज",
		"not_in_accountGroup":"यह सदस्यता समूह ड्रा के लिए पात्र नहीं है",
		"iinvalid_discount_setting":"ऑफ़र सेटिंग सक्षम नहीं है",
		"empty_slices":"पुरस्कार मौजूद नहीं है",
		"out_of_qualification":" के लिए पात्र नहीं है",
		"out_of_draws_times":"कोई ड्रा नहीं बचा",
		"checked_finish":"आज का चेक-इन पूरा हो गया",
		"checked_over":"सभी चेक-इन पूरे हो गए",
		"no_data":"इवेंट अभी खुला नहीं है, इसलिए बने रहें ",
		"sign":"Sign",
		"qualifiy":"भाग लेने के लिए पात्र नहीं है",
		"slice_name":"पुरस्कार",
		"amount":"अंक",
		"darwReport":"लॉटरी रिकॉर्ड",
		"searchDarwReport":"लॉटरी रिकॉर्ड देखें",
	  }
}
